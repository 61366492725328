import React from 'react';
import { createRoot } from 'react-dom/client';
// import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { sercomStore } from './store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { registerLicense } from '@syncfusion/ej2-base';
import { SercomBaseStyles, SyncfusionStyles } from './index.styled';

// style + assets
import './assets/scss/style.scss';

// Import
import './i18n';
// import i18n (needs to be bundled ;))
// import './i18n/config';

// Registering Syncfusion license key
registerLicense(
  'Mgo+DSMBaFt+QHJqVk1hXk5Hd0BLVGpAblJ3T2ZQdVt5ZDU7a15RRnVfRF1gS31Wf0ZlUHtfcA==;Mgo+DSMBPh8sVXJ1S0R+X1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jT35QdEZmUHxYeXFQRg==;ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXhTdUdgXHZddn1TTmA=;MjMwMjQ0M0AzMjMxMmUzMDJlMzBEYkVEOWxhMng1MWN0bkhzM2JUQitZeHhKdHhrbDlQZ1hGYzQ1YWpGd1dZPQ==;MjMwMjQ0NEAzMjMxMmUzMDJlMzBaUXdEUmFvVkE4RUI3d2tHd2prSlJEblluZGd4bFZwRVhXMTNLa3l6d3VrPQ==;NRAiBiAaIQQuGjN/V0d+Xk9HfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5VdkZhWnpXc3NVRWRc;MjMwMjQ0NkAzMjMxMmUzMDJlMzBFUTRjU2hqeUxYOXlzNjVlNGxSL0N3cDVYOFFqR1dxYm1WcEhTVWV4dk53PQ==;MjMwMjQ0N0AzMjMxMmUzMDJlMzBCSUtkb3R6VHE2SVFNVEpKVktYclo1NXNEM0d3OWZ0ZWEybnZhM051RTNnPQ==;Mgo+DSMBMAY9C3t2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXhTdUdgXHZdd3VVR2A=;MjMwMjQ0OUAzMjMxMmUzMDJlMzBXbVdpckpvRXNxcnFLUFpsWENDb1RRTXhVUE9hK2pCTzdBdlBNbDNLZEJnPQ==;MjMwMjQ1MEAzMjMxMmUzMDJlMzBWdkR4YnUvQlFqL3F0RTFTUEpOMkVoMjFtSXBwNlpyRjhzWkZCWGQ4NzFrPQ==;MjMwMjQ1MUAzMjMxMmUzMDJlMzBFUTRjU2hqeUxYOXlzNjVlNGxSL0N3cDVYOFFqR1dxYm1WcEhTVWV4dk53PQ=='
);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    {/* <React.StrictMode> */}
    <SercomBaseStyles />
    <SyncfusionStyles />
    <Provider store={sercomStore}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

