import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import SercomGrid from '../../components/Grid';
import { DataManager } from '@syncfusion/ej2-data';
import { GridComponent, RecordClickEventArgs, SortSettingsModel } from '@syncfusion/ej2-react-grids';
import SercomEnums from '../../support/sercom-enums';
import { usePopupManager } from 'react-popup-manager';
import { useTranslation } from 'react-i18next';
import AdminService from '../../services/admin-service';
import SercomConstants from '../../support/sercom-constants';
import SercomButton from '../../components/SercomButton';
import AccountPopup from './AccountPopup';
import { DeleteOutline, EditOutlined, FiberManualRecord } from '@mui/icons-material';
import moment from 'moment';
import SercomMenu from '../../components/SercomMenu';
import { SercomTypes } from '../../support/sercom-types';
import SubscriptionPopup from './SubscriptionPopup';
import snackbarHelper from '../../helpers/snackbar-helper';

const CompaniesPage = () => {
  const [dataSource, setDataSource] = useState<DataManager | undefined>(undefined);
  const companyGridRef = useRef<GridComponent | null>(null);
  const popupManager = usePopupManager();
  const { t } = useTranslation();

  const subscriptionPlanStrings: { [key: string]: string } = {
    [SercomEnums.SubscriptionPlan.Solo]: t('Solo'),
    [SercomEnums.SubscriptionPlan.Squad]: t('Squad'),
    [SercomEnums.SubscriptionPlan.Enterprise]: t('Enterprise'),
  };

  const getSubscriptionPlanEnumOptions = () =>
    Object.keys(subscriptionPlanStrings).map((key) => ({
      label: subscriptionPlanStrings[key],
      value: parseInt(key),
    }));

  const subscriptionPlanValueAccessor = (field: string, data: any) => subscriptionPlanStrings[data[field]];

  useEffect(() => {
    async function init() {
      const dataManager = await AdminService.getCompaniesDatasource();
      setDataSource(dataManager);
    }
    init();
  }, []);

  const openCreateAccountPopup = (id?: string) => {
    popupManager.open(AccountPopup, {
      id: id,
      action: () => {
        companyGridRef.current?.refresh?.();
      },
      buttonText: t('Create'),
      onClose: () => {},
    });
  };

  const openSubscriptionPopup = (id?: string) => {
    popupManager.open(SubscriptionPopup, {
      id: id,
      action: () => {
        companyGridRef.current?.refresh?.();
      },
      buttonText: t('Update'),
      onClose: () => {},
    });
  };

  const openCloseCompany = async (id?: string) => {
    if (id) {
      const result = await AdminService.closeCompany(id);
      if (result.isSuccess && result.data) {
        if (result.data.errorCode && result.data.errorCode != SercomEnums.ErrorCode.None) {
          snackbarHelper.showErrorFromCode(result.data.errorCode, t);
        } else {
          snackbarHelper.showSuccess(t('CompanyClosedSuccessfully'));
          companyGridRef.current?.refresh?.();
        }
      } else {
        snackbarHelper.showErrorFromCode(SercomEnums.ErrorCode.ServerError, t);
      }
    }
  };

  const rowStatusTemplate = (prop: any) => {
    const { ExpirationDate } = prop;

    return (
      <FiberManualRecord
        color={moment(ExpirationDate).isBefore(moment()) ? 'error' : moment(ExpirationDate).isSame(moment()) ? 'warning' : 'success'}
      />
    );
  };

  const statusTemplate: any = rowStatusTemplate;

  const rowMenuTemplate = (prop: any) => {
    const items: SercomTypes.SouvlakiMenuItemProps[] = [];
    items.push({
      text: t('Edit'),
      icon: <EditOutlined sx={{ width: 20, mr: 2 }} />,
      action: () => openSubscriptionPopup(prop.Id),
    });

    if (moment(prop.ExpirationDate).isBefore(moment())) {
      items.push({
        text: t('Close'),
        icon: <DeleteOutline sx={{ width: 20, mr: 2 }} />,
        action: () => openCloseCompany(prop.Id),
      });
    }

    return <SercomMenu items={items} />;
  };

  const menuColumn: any = rowMenuTemplate;

  const onRecordClick = (args: RecordClickEventArgs) => {
    if (!args.cell?.classList?.contains('e-templatecell')) {
      const rowData = args.rowData as { Id?: string } | undefined;
      const id = rowData?.Id;
      openSubscriptionPopup(id);
    }
  };

  const columns = [
    { field: 'Id', type: 'string', headerText: 'Id', isPrimaryKey: true, visible: false, showInColumnChooser: false },
    { template: statusTemplate, headerText: t('On'), visible: true, allowSearch: false, width: 10 },
    { field: 'Name', type: 'string', headerText: t('Name'), visible: true, allowSearch: true },
    { field: 'FullName', type: 'string', headerText: t('UserName'), visible: true, allowSearch: true },
    { field: 'Email', type: 'string', headerText: t('Email'), visible: true, allowSearch: true },
    { field: 'Phone', type: 'string', headerText: t('Phone'), visible: false, allowSearch: true },
    {
      field: 'CreationDate',
      type: 'date',
      headerText: t('CreationDate'),
      format: { type: 'date', format: SercomConstants.DateFormat.SYNCFUSION_ISO },
      visible: true,
    },
    {
      field: 'LastActivityDate',
      type: 'date',
      headerText: t('LastLogin'),
      format: { type: 'date', format: SercomConstants.DateTimeFormat.SYNCFUSION_ISO },
      visible: true,
    },
    {
      field: 'SubscriptionPlan',
      type: 'number',
      headerText: t('Plan'),
      valueAccessor: subscriptionPlanValueAccessor,
      enumFilter: getSubscriptionPlanEnumOptions(),
      visible: true,
    },
    {
      field: 'CountMembers',
      type: 'number',
      headerText: t('Members'),
      visible: true,
    },
    {
      field: 'MaxMembers',
      type: 'number',
      headerText: t('MaxMembers'),
      visible: true,
    },
    {
      field: 'ExpirationDate',
      type: 'date',
      headerText: t('ExpirationDate'),
      format: { type: 'date', format: SercomConstants.DateFormat.SYNCFUSION_ISO },
      visible: true,
    },
    {
      field: 'ClientsCount',
      type: 'number',
      headerText: t('Clients'),
      visible: false,
    },
    {
      field: 'RequestsCount',
      type: 'number',
      headerText: t('Requests'),
      visible: false,
    },
    {
      field: 'QuotesCount',
      type: 'number',
      headerText: t('Quotes'),
      visible: false,
    },
    {
      field: 'InvoicesCount',
      type: 'number',
      headerText: t('Invoices'),
      visible: false,
    },
    {
      field: 'JobsCount',
      type: 'number',
      headerText: t('Jobs'),
      visible: false,
    },
    { field: 'Address', type: 'string', headerText: t('Address'), visible: false, allowSearch: true },
    { field: 'Url', type: 'string', headerText: t('Url'), visible: false, allowSearch: false },
    { field: 'Note', type: 'string', headerText: t('Note'), visible: false, allowSearch: true },
    { headerText: '', template: menuColumn, width: 20, showInColumnChooser: false },
  ];
  const sortSettings: SortSettingsModel = { columns: [{ field: 'Name', direction: 'Ascending' }] };
  return dataSource ? (
    dataSource && (
      <Box sx={{ borderRadius: 2, p: 5, display: 'flex', flexDirection: 'column', background: '#ffffff' }}>
        <Typography variant="h3" sx={{ mb: 3 }}>
          {t('Companies')}
        </Typography>
        <Grid item xs={3} sx={{ mb: 4 }}>
          <SercomButton text={t('CreateAccount')} variant="contained" onClick={() => openCreateAccountPopup()} />
        </Grid>
        <SercomGrid
          gridRef={companyGridRef}
          dataSource={dataSource}
          columns={columns}
          gridCustomizationType={SercomEnums.GridCustomizationType.Company}
          recordClick={onRecordClick}
          sortSettings={sortSettings}
        />
      </Box>
    )
  ) : (
    <div></div>
  );
};

export default CompaniesPage;

