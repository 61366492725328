import styled from 'styled-components';
import SercomConstants from '../../support/sercom-constants';
import { GridComponent } from '@syncfusion/ej2-react-grids';
import ColorHelper from '../../helpers/color-helper';

export const StyledGrid = styled(GridComponent)`
  border: none;

  .e-emptyrow {
    display: none;
  }

  .e-row {
    cursor: ${(props: any) => (props.recordClick ? 'pointer' : null)};
  }

  .e-headercontent {
    border-right: none;
  }

  .e-gridheader .e-headercelldiv {
    font-size: 14px;
    font-weight: 500;
    color: ${SercomConstants.Color.TYPEFACE_6};
  }

  .e-headercell .e-filtermenudiv:not(.e-filtered) {
    display: none;
  }

  .e-headercell:hover .e-filtermenudiv {
    display: block;
  }

  .e-headercell .e-filtered:before {
    color: ${SercomConstants.Color.PRIMARY};
  }

  .e-gridheader tr th:first-child {
    padding: 0 10px;
  }
  .e-toolbar {
    margin-bottom: 20px;
    background: none;
    border: none;
  }

  .e-toolbar .e-toolbar-items {
    background: none;
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item {
    margin-right: 20px;
  }

  .e-toolbar .e-tbar-btn {
    color: ${SercomConstants.Color.TYPEFACE_4};
    background: none;
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .m-grid-export-excel-icon:after {
    content: 'cloud_download';
    font-family: Material Icons Outlined;
    font-size: 24px;
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .m-grid-export-pdf-icon:after {
    content: 'picture_as_pdf';
    font-family: Material Icons Outlined;
    font-size: 24px;
  }

  .e-toolbar-item.e-cc-toolbar .e-tbar-btn-text {
    display: none;
  }

  .e-toolbar-item.e-cc.e-ccdiv.e-cc-toolbar {
    padding: 0;
  }

  .e-toolbar-item.e-cc-toolbar .e-columnchooser-btn:before {
    content: 'view_week';
    font-family: Material Icons Outlined;
    font-size: 24px;
    vertical-align: middle;
  }

  .e-toolbar .e-toolbar-items .e-toolbar-right .e-toolbar-item.e-search-wrapper {
    border: 1px solid ${SercomConstants.Color.SURFACE_4};
    border-radius: ${SercomConstants.BORDER_RADIUS};
    padding: 5px 10px;
    margin-right: 0;
    min-height: 35px;
    height: 35px;
    width: 200px;
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search.e-input-group {
    border: none;
    opacity: 1;

    &:before,
    &:after {
      display: none;
    }
  }

  .e-toolbar .e-search-wrapper .e-search-icon {
    padding-right: 10px;
    font-size: 16px;
  }

  .e-toolbar-item .e-search .e-input {
    order: 1;
  }
  .e-pager .e-numericcontainer,
  .e-pager .e-numericitem {
    display: none;
  }

  .e-pager .e-icons {
    font-size: 12px;
  }

  .e-pager .e-pagerdropdown {
    width: 50px;
  }

  .e-pager .e-pagerdropdown .e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) {
    border: none;
  }

  .e-pager .e-input-group > .e-input {
    width: 25px;
    font-size: 14px;
    font-weight: 500;
  }

  .e-pager .e-pagerconstant {
    font-size: 14px;
    font-weight: 500;
    color: ${SercomConstants.Color.TYPEFACE_6};
    margin-left: 5px;
    margin-bottom: 8px;
  }

  .e-pagercontainer {
    margin: 5px 0 0 0;
  }

  .e-pagercontainer .e-icons {
    padding-left: 0;
    padding-right: 0;
  }

  .e-pager div.e-parentmsgbar {
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
    padding-right: 10px;
  }

  .e-pager .e-pagesizes {
    margin-left: 40px;
  }

  .e-pager .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
  .e-pager .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
    margin-left: 0;
  }

  .e-pager .e-pagercontainer .e-first,
  .e-pager .e-pagercontainer .e-prev,
  .e-pager .e-pagercontainer .e-next,
  .e-pager .e-pagercontainer .e-last {
    color: ${SercomConstants.Color.TYPEFACE_5};
    border-radius: 50%;
    padding: 10px;
    margin: 0;

    &:not(.e-disable):hover {
      background: ${SercomConstants.Color.SURFACE_4};
    }
  }

  .e-css.e-checkbox-wrapper .e-frame.e-check,
  .e-css.e-checkbox-wrapper:hover .e-frame.e-check {
    background-color: #ffffff;
    border-color: ${SercomConstants.Color.PRIMARY};
    color: ${SercomConstants.Color.PRIMARY};
  }

  .e-dialog .e-dlg-header {
    display: none;
  }

  .e-ccdlg .e-checkbox-wrapper .e-frame {
    border: 2px solid ${SercomConstants.Color.TYPEFACE_4};
  }

  .e-ccdlg .e-footer-content {
    border-radius: 0 0 ${SercomConstants.BORDER_RADIUS} ${SercomConstants.BORDER_RADIUS};
  }

  .e-ccdlg .e-cc-searchdiv {
    display: flex;
  }

  .e-ccdlg .e-cc.e-input {
    order: 2;
  }

  .e-ccdlg .e-cc-searchdiv span.e-ccsearch-icon.e-icons {
    order: 1;
    padding: 3px 5px 5px;
    font-size: 18px;
    color: ${SercomConstants.Color.TYPEFACE_4};
  }

  .e-ccdlg {
    border-radius: ${SercomConstants.BORDER_RADIUS};
    box-shadow: ${SercomConstants.Color.BOX_SHADOW_12};
    border: 1px solid ${SercomConstants.Color.SURFACE_4};
  }

  .e-ccdlg .e-checkbox-wrapper .e-frame {
    border: 2px solid ${SercomConstants.Color.TYPEFACE_4};
  }

  .e-ccdlg .e-checkbox-wrapper .e-frame.e-check,
  .e-ccdlg .e-checkbox-wrapper:hover .e-frame.e-check {
    background-color: transparent;
    border: 2px solid ${SercomConstants.Color.PRIMARY};
    color: ${SercomConstants.Color.PRIMARY};
  }

  .e-ccdlg .e-dlg-content .e-cc-searchdiv.e-cc.e-input-group {
    border: none;
    margin-top: 20px;
  }

  .e-ccdlg .e-dlg-content {
    margin: 0;
  }

  .e-ccdlg .e-footer-content,
  .e-filter-popup .e-footer-content {
    display: flex;
    justify-content: flex-end;
  }

  .e-ccdlg .e-footer-content .e-cc_okbtn,
  .e-filter-popup .e-footer-content .e-flmenu-okbtn {
    order: 1;
  }

  .e-filter-popup {
    border-radius: ${SercomConstants.BORDER_RADIUS};
    box-shadow: ${SercomConstants.Color.BOX_SHADOW_12};
    border: 1px solid ${SercomConstants.Color.SURFACE_4};
    overflow: hidden;
  }

  .e-filter-popup .e-footer-content {
    padding-top: 0;
  }

  .e-filter-popup .e-date-icon {
    margin-left: 5px;
  }

  .e-filter-popup .e-input-group .e-icons,
  .e-filter-popup .e-input-group .e-ddl-icon {
    color: ${SercomConstants.Color.TYPEFACE_3};

    &:hover {
      color: ${SercomConstants.Color.TYPEFACE_4};
    }

    &:active {
      color: ${SercomConstants.Color.TYPEFACE_4};
    }
  }

  .e-filter-popup .e-input-group {
    border-radius: ${SercomConstants.BORDER_RADIUS};
    border: 1px solid ${SercomConstants.Color.SURFACE_4} !important;
    padding: 5px 10px;
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    color: ${SercomConstants.Color.TYPEFACE_5};
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item .e-icons {
    color: ${SercomConstants.Color.TYPEFACE_4};
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item .e-icons:hover {
    color: ${SercomConstants.Color.TYPEFACE_5};
  }

  .e-toolbar .e-toolbar-item .e-tbar-btn:hover .e-icons {
    color: ${SercomConstants.Color.TYPEFACE_5};
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .m-grid-export-excel-icon,
  .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .m-grid-export-pdf-icon {
    margin-right: 8px;
    line-height: 24px;
    min-height: 24px;
    margin-top: 4px;
  }

  .e-dragclone.e-headerclone.e-notallowedcur,
  .e-dragclone.e-headerclone.e-defaultcur,
  .e-dragclone.e-headerclone {
    background: ${ColorHelper.applyOpacity(SercomConstants.Color.PRIMARY, 0.3)};
    border-radius: ${SercomConstants.BORDER_RADIUS};
    padding: 12px 0 0 0;
    min-height: 38px;
    box-shadow: ${SercomConstants.Color.BOX_SHADOW_16};
    font-size: 14px;
    border: none;
  }

  .e-checkboxfilter .e-searchbox {
    display: none;
  }

  .e-dialog.e-checkboxfilter {
    min-height: 150px;
  }

  .e-checkboxfilter .e-checkboxlist {
    height: auto;
    min-height: 0;
    margin: 0;
  }

  .e-checkboxfilter .e-checkboxlist .e-ftrchk.e-chkfocus {
    background: none;
  }

  .e-checkboxfilter .e-footer-content .e-btn.e-primary {
    order: 1;
  }

  .n-date-column-filter-popup .e-flm_optrdiv,
  .n-enum-column-filter-popup .e-flm_optrdiv {
    display: none;
  }

  .n-date-column-filter-popup .e-flmenu-valuediv,
  .n-enum-column-filter-popup .e-flmenu-valuediv {
    padding: 0;
  }

  .n-enum-column-filter-popup .e-checkbox-wrapper {
    margin-bottom: 10px;
  }

  .n-date-column-header-date {
    margin-left: 10px;
    color: ${SercomConstants.Color.PRIMARY};
    font-size: 12px;
  }

  .e-toolbar .e-toolbar-items .e-toolbar-item.n-hidden {
    display: none;
  }

  .m-grid-no-data-root {
    display: none;
  }

  .m-grid-has-no-data .m-grid-no-data-root {
    display: block;
  }
`;

