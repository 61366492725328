import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SercomTypes } from '../../support/sercom-types';
import SercomDialog from '../../components/SercomDialog';
import SubscriptionService from '../../services/subscription-service';
import SercomEnums from '../../support/sercom-enums';
import SercomHelper from '../../support/sercom-helper';
import { useTranslation } from 'react-i18next';

const SubscriptionPopup = (props: SercomTypes.SercomDialog) => {
  const [subscription, setSubscription] = useState<SercomTypes.Subscription | null>(null);
  const { t } = useTranslation();

  const subscriptionPlanStrings: { [key: string]: string } = {
    [SercomEnums.SubscriptionPlan.Solo]: 'Solo',
    [SercomEnums.SubscriptionPlan.Squad]: 'Squad',
    [SercomEnums.SubscriptionPlan.Enterprise]: 'Enterprise',
  };
  const subscriptionFrequencyStrings: { [key: string]: string } = {
    [SercomEnums.SubscriptionFrequency.Month]: t('Monthly'),
    [SercomEnums.SubscriptionFrequency.Year]: t('Yearly'),
  };

  useEffect(() => {
    async function init() {
      const result = await SubscriptionService.getCurrentSubscription();
      if (result.isSuccess) {
        setSubscription(result.data);
      }
    }

    init();
  }, []);

  const getSubscriptionInformation = () => {
    return (
      <Box>
        <Paper
          elevation={2}
          sx={{ minWidth: 360, backgroundColor: '#FFFFFF', borderRadius: 3, p: 3, mb: 0.2, ml: 0.2, overflowY: 'scroll' }}
        >
          {subscription && (
            <Stack direction="column" spacing={1} sx={{ alignItems: 'baseline' }}>
              <Typography variant="h2" mb={2}>
                {t('PlanDetails')}
              </Typography>
              <Divider sx={{ mt: 1, mb: 1 }} />
              {subscription?.plan != null && (
                <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="h2">{subscriptionPlanStrings[subscription.plan]}</Typography>
                  <Typography variant="overline">
                    {t('MembersUsed', { members: subscription?.membersCount ?? '--', maxMembers: subscription.maxMembersCount ?? '--' })}
                  </Typography>
                </Box>
              )}
              {subscription?.frequency != null && <Typography variant="h5" pb={1}></Typography>}
              <Typography variant="h4" pb={1}>
                {t('FrequencyValue', {
                  frequency: subscription?.frequency != null ? subscriptionFrequencyStrings[subscription.frequency] : '--',
                })}
              </Typography>
              <Stack direction="row">
                <Typography variant="h4" pb={1}>
                  {t('AmountValue', {
                    amount: subscription?.price ? SercomHelper.displayFormattedAmountWithCurrency(subscription?.price) : '--',
                  })}
                </Typography>
                <Typography variant="caption" pb={1} pl={1}>
                  {`+ ${t('ApplicableTaxes')}`}
                </Typography>
              </Stack>
              <Typography variant="h4" pb={1}>
                {t('NextPaymentValue', { date: subscription?.nextPaymentDate ?? '--' })}
              </Typography>
            </Stack>
          )}
        </Paper>
      </Box>
    );
  };

  return (
    <SercomDialog title={t('Subscription')} isOpen={props.isOpen} onClose={props.onClose} action={undefined}>
      {getSubscriptionInformation()}
    </SercomDialog>
  );
};

export default SubscriptionPopup;

