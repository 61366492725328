const SyncfusionConstants = {
  TRANSLATION: {
    'fr-CA': {
      datepicker: {
        // eslint-disable-next-line quotes
        today: "Aujourd'hui",
      },
      daterangepicker: {
        applyText: 'Appliquer',
        cancelText: 'Annuler',
        customRange: 'Période personnalisée',
        days: 'Jours',
        endLabel: 'Date de fin',
        selectedDays: 'Jours sélectionnés',
        startLabel: 'Date de début',
      },
      grid: {
        CancelButton: 'Annuler',
        ChooseDate: 'Sélectionnez une date',
        ClearButton: 'Effacer',
        Columnchooser: 'Colonnes',
        Contains: 'Contient',
        EndsWith: 'Se termine par',
        EnterValue: 'Entrez une valeur',
        Equal: 'Est égal à',
        FilterButton: 'Filtrer',
        GreaterThan: 'Est supérieur à',
        GreaterThanOrEqual: 'Est supérieur ou égal à',
        GroupDropArea: 'Faites glisser un en-tête de colonne et déposer ici pour grouper par cette colonne.',
        LessThan: 'Est inférieur à',
        LessThanOrEqual: 'Est inférieur ou égal à',
        // eslint-disable-next-line quotes
        NotEqual: "N'est pas égal à",
        OKButton: 'OK',
        Search: 'Rechercher',
        SearchColumns: 'Rechercher dans les colonnes',
        SelectAll: 'Tout sélectionner',
        StartsWith: 'Débute par',
      },
      pager: {
        currentPageInfo: '{0} de {1} pages',
        firstPageTooltip: 'Allez à la première page',
        lastPageTooltip: 'Allez à la dernière page',
        nextPageTooltip: 'Allez à la page suivante',
        pagerDropDown: 'Éléments par page',
        previousPageTooltip: 'Allez à la page précédente',
        totalItemInfo: '({0} élément)',
        totalItemsInfo: '({0} éléments)',
      },
      schedule: {
        day: 'Jour',
        week: 'Semaine',
        workWeek: 'Semaine de travail',
        month: 'Mois',
        agenda: 'Agenda',
        weekAgenda: 'Semaine Agenda',
        // eslint-disable-next-line quotes
        today: "Aujourd'hui",
        timelineDay: 'Chronologie',
        delete: 'Supprimer',
        saveButton: 'Sauvegarder',
        cancelButton: 'Annuler',
        deleteButton: 'Supprimer',
        close: 'Fermer',
        cancel: 'Annuler',
        next: 'Suivante',
        previous: 'Précédente',
      },
    },
    'en-US': {
      datepicker: {
        today: 'Today',
      },
      daterangepicker: {
        applyText: 'Apply',
        cancelText: 'Cancel',
        customRange: 'Custom Range',
        days: 'Days',
        endLabel: 'End date',
        selectedDays: 'Selected days',
        startLabel: 'Start date',
      },
      grid: {
        CancelButton: 'Cancel',
        ChooseDate: 'Choose a date',
        ClearButton: 'Clear',
        Columnchooser: 'Columns',
        Contains: 'Contains',
        EndsWith: 'Ends with',
        EnterValue: 'Enter a value',
        Equal: 'Is equal to',
        FilterButton: 'Filter',
        GreaterThan: 'Is greater than',
        GreaterThanOrEqual: 'Is greater than or equal to',
        GroupDropArea: 'Drag a column header and drop it here to group by that column.',
        LessThan: 'Is less than',
        LessThanOrEqual: 'Is less than or equal to',
        NotEqual: 'Is not equal to',
        OKButton: 'OK',
        Search: 'Search',
        SearchColumns: 'Search columns',
        SelectAll: 'Select all',
        StartsWith: 'Starts with',
      },
      pager: {
        currentPageInfo: '{0} of {1} pages',
        firstPageTooltip: 'Go to first page',
        lastPageTooltip: 'Go to last page',
        nextPageTooltip: 'Go to next page',
        pagerDropDown: 'Items per page',
        previousPageTooltip: 'Go to previous page',
        totalItemInfo: '({0} item)',
        totalItemsInfo: '({0} items)',
      },
      schedule: {
        day: 'Day',
        week: 'Week',
        workWeek: 'Work Week',
        month: 'Month',
        agenda: 'Agenda',
        weekAgenda: 'Week Agenda',
        workWeekAgenda: 'Work Week Agenda',
        monthAgenda: 'Month Agenda',
        today: 'Today',
        noEvents: 'No events',
        emptyContainer: 'There are no events scheduled on this day.',
        allDay: 'All day',
        start: 'Start',
        end: 'End',
        more: 'more',
        close: 'Close',
        cancel: 'Cancel',
        noTitle: '(No Title)',
        delete: 'Delete',
        deleteEvent: 'Delete Event',
        deleteMultipleEvent: 'Delete Multiple Events',
        selectedItems: 'Items selected',
        deleteSeries: 'Delete Series',
        edit: 'Edit',
        editSeries: 'Edit Series',
        editEvent: 'Edit Event',
        createEvent: 'Create',
        subject: 'Subject',
        addTitle: 'Add title',
        moreDetails: 'More Details',
        save: 'Save',
        editContent: 'Do you want to edit only this event or entire series?',
        deleteRecurrenceContent: 'Do you want to delete only this event or entire series?',
        deleteContent: 'Are you sure you want to delete this event?',
        deleteMultipleContent: 'Are you sure you want to delete the selected events?',
        newEvent: 'New Event',
        title: 'Title',
        location: 'Location',
        description: 'Description',
        timezone: 'Timezone',
        startTimezone: 'Start Timezone',
        endTimezone: 'End Timezone',
        repeat: 'Repeat',
        saveButton: 'Save',
        cancelButton: 'Cancel',
        deleteButton: 'Delete',
        recurrence: 'Recurrence',
        wrongPattern: 'The recurrence pattern is not valid.',
        seriesChangeAlert:
          'The changes made to specific instances of this series will be cancelled and those events will match the series again.',
        createError:
          'The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
        recurrenceDateValidation:
          'Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.',
        sameDayAlert: 'Two occurrences of the same event cannot occur on the same day.',
        editRecurrence: 'Edit Recurrence',
        repeats: 'Repeats',
        alert: 'Alert',
        startEndError: 'The selected end date occurs before the start date.',
        invalidDateError: 'The entered date value is invalid.',
        ok: 'Ok',
        occurrence: 'Occurrence',
        series: 'Series',
        previous: 'Previous',
        next: 'Next',
        timelineDay: 'Timeline Day',
        timelineWeek: 'Timeline Week',
        timelineWorkWeek: 'Timeline Work Week',
        timelineMonth: 'Timeline Month',
        expandAllDaySection: 'Expand',
        collapseAllDaySection: 'Collapse',
      },
      recurrenceeditor: {
        none: 'None',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        month: 'Month',
        yearly: 'Yearly',
        never: 'Never',
        until: 'Until',
        count: 'Count',
        first: 'First',
        second: 'Second',
        third: 'Third',
        fourth: 'Fourth',
        last: 'Last',
        repeat: 'Repeat',
        repeatEvery: 'Repeat Every',
        on: 'Repeat On',
        end: 'End',
        onDay: 'Day',
        days: 'Day(s)',
        weeks: 'Week(s)',
        months: 'Month(s)',
        years: 'Year(s)',
        every: 'every',
        summaryTimes: 'time(s)',
        summaryOn: 'on',
        summaryUntil: 'until',
        summaryRepeat: 'Repeats',
        summaryDay: 'day(s)',
        summaryWeek: 'week(s)',
        summaryMonth: 'month(s)',
        summaryYear: 'year(s)',
      },
    },
  },
};

export default SyncfusionConstants;

