const ColorHelper = {
  applyOpacity: (hexString: string, alpha: number | string) => {
    const hex = Number(`0x${hexString.replace('#', '')}`);
    const r = (hex >> 16) & 0xff;
    const g = (hex >> 8) & 0xff;
    const b = hex & 0xff;
    let a = 1;

    if (alpha?.constructor === Number) {
      a = alpha;
    } else if (alpha?.constructor === String) {
      a = (Number(`0x${alpha}`) & 0xff) / 0xff;
    }

    a = Math.min(1, Math.max(0, a));

    return `rgba(${r}, ${g}, ${b}, ${a.toFixed(2)})`;
  },
};

export default ColorHelper;
