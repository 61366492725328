import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './assets/locales/translation-en.json';

// the translations
export const resources = {
  en: {
    translation: translationEn,
  },
};

i18next.use(initReactI18next).init({
  // pass the i18n instance to react-i18next.
  // for all init options read: https://www.i18next.com/overview/configuration-options
  // lng: 'en', // window.sercomCurrentCulture,
  supportedLngs: ['en', 'fr'],
  nonExplicitSupportedLngs: true,
  // 'ns1',
  // ns: ['ns1'],
  resources,
  fallbackLng: 'en',
});

export default i18next;

