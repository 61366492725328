import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';
import { SercomTypes } from '../support/sercom-types';

const SercomDatePicker = (props: SercomTypes.SercomDatePicker) => {
  return (
    <DatePicker
      slotProps={{
        textField: { size: 'small' },
        field: {
          clearable: props.onClear != undefined ? props.onClear : false,
          onClear: () => props.onClear,
        },
      }}
      sx={{ width: '100%' }}
      label={props.title}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

export default SercomDatePicker;

