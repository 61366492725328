import './App.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ThemeProvider } from '@mui/material/styles';

import SercomRoutes from './support/SercomRoutes';
import { metadataActions } from './store/metadata/metadata-actions';
// import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PopupProvider } from 'react-popup-manager';

// defaultTheme
import themes from './themes';

import SercomEnums from './support/sercom-enums';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { syncfusionLocaleInit } from './helpers/syncfusion-locale-helper';

syncfusionLocaleInit();

type AppProps = {
  isLogged: boolean;
  companyId: number | null;
  permission: number;
  language: number;
  displayMode: SercomEnums.DisplayMode;
  getLoggedInUserData: (callback?: Function) => void;
  getDisplayMode: (callback?: Function) => void;
};

const App = (props: AppProps) => {
  const { isLogged, companyId, permission, language, displayMode, getLoggedInUserData, getDisplayMode } = props;
  const [isReady, setIsReady] = useState<boolean>(false);
  const [custosercomtion, setcustosercomtion] = useState<any>({ mode: 'light' });

  useEffect(() => {
    getLoggedInUserData(() => {
      setIsReady(true);
    });
    getDisplayMode(() => {
      setcustosercomtion({
        mode: displayMode == SercomEnums.DisplayMode.Dark ? 'dark' : 'light',
      });
    });
  }, []);

  useEffect(() => {
    getLoggedInUserData(() => {});
    setcustosercomtion({
      mode: displayMode == SercomEnums.DisplayMode.Dark ? 'dark' : 'light',
    });
  }, [displayMode]);

  // Update the theme only if the mode changes
  // themes = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  // Update the theme only if the mode changes
  // const theme = React.useMemo(() => createTheme(getDesignTokens('dark')), ['dark']);

  return (
    <ThemeProvider theme={themes(custosercomtion)}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider maxSnack={3}>
          {/* action={(snackbarKey) => <SercomSnackbarCloseButton snackbarKey={snackbarKey} />} */}
          <PopupProvider>
            {isReady && <SercomRoutes isLogged={isLogged} companyId={companyId} permission={permission} language={language} />}
          </PopupProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
  // {<ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>}
  // {</ThemeProvider>}
};

const mapStateToProps = (state: any) => ({
  isLogged: state.metadata.currentUser.isLogged,
  companyId: state.metadata.currentUser.companyId,
  displayMode: state.metadata.displayMode,
  permission: state.metadata.currentUser.permission,
  language: state.metadata.currentUser.language,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getLoggedInUserData: metadataActions.getLoggedInUserData,
      getDisplayMode: metadataActions.getDisplayMode,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);

