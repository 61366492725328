import React, { useEffect } from 'react';
import { Box } from '@mui/material';

const HomePage = () => {
  useEffect(() => {
    async function init() {}
    init();
  }, []);

  return (
    <Box sx={{ /* height: '100%', */ borderRadius: 2, p: 5, display: 'flex', flexDirection: 'column', background: '#ffffff' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}></Box>
    </Box>
  );
};

export default HomePage;

