import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { SercomTypes } from '../support/sercom-types';
import { v4 as uuid } from 'uuid';

const SercomSelect = (props: SercomTypes.SercomSelect) => {
  const guid = uuid();
  return (
    <FormControl fullWidth>
      <InputLabel id={guid} sx={{ top: '-4px' }}>
        {props.title}
      </InputLabel>
      <Select
        name={props.name ?? undefined}
        fullWidth
        labelId={guid}
        value={props.selectedItem}
        error={props.error}
        label={props.title}
        disabled={props.disabled ?? false}
        size="small"
        onChange={props.onChange}
        sx={{ display: 'flex', verticalAlign: 'middle', minWidth: 120 }}
      >
        {props.items.map((item) => (
          <MenuItem key={item.key} value={item.key}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SercomSelect;
