import { createTheme } from '@mui/material/styles';

// assets
import colors from '../assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} custosercomtion custosercomtion parameter object
 */

export const theme = (custosercomtion) => {
  const color = colors;

  const themeOption = {
    colors: color,
    heading: color.primaryMain,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.primaryMain,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    custosercomtion,
    // mode: 'dark',
    // ...(mode === 'light'
    //   ? {
    //       // palette values for light mode
    //       primary: amber,
    //     }
    //   : {
    //       primary: deepOrange,
    //     }),
  };

  const themeOptions = {
    direction: 'ltr',
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        'minHeight': '48px',
        'height': '72px',
        'padding': '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
    typography: themeTypography(themeOption),
  };

  const themes = createTheme(themeOptions);
  themes.components = componentStyleOverrides(themeOption);

  return themes;
};

export default theme;

