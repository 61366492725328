import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { metadataActions } from '../../store/metadata/metadata-actions';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, TextField, Typography, Button, Stack, useMediaQuery, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import snackbarHelper from '../../helpers/snackbar-helper';
import { t } from 'i18next';
import SercomLink from '../../components/SercomLink';
import { useNavigate } from 'react-router-dom';

type LoginProps = {
  login: (email: string, password: string, successCallback?: Function, errorCallback?: Function) => void;
  googleLogin: (tokenId: string, successCallback?: Function, errorCallback?: Function) => void;
};

const LoginPage = (props: LoginProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const { login /* , googleLogin */ } = props;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const onEmailChange = (event: any) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };

  const onShowHidePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const errorCallback = () => {
    setEmailError(true);
    setPasswordError(true);
    snackbarHelper.showError(t('InvalidEmailPassword'));
  };

  const onLoginClick = () => {
    setPasswordError(!email);
    setPasswordError(!password);

    if (email && password) {
      login(email, password, undefined, errorCallback);
    } else {
      snackbarHelper.showError(t('InvalidEmailPassword'));
    }
  };

  const onForgotPassword = () => {
    navigate('/forgot_password');
  };

  const onLoginKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onLoginClick();
    }
  };

  // const onGoogleSuccess = (response: any) => {
  //   googleLogin(response['tokenId'], undefined, () => setWrongCredentialsMessage('Invalid Login'));
  // };
  // const onGoogleFailure = (response: any) => {
  //   console.log(response);
  // };

  // const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  // const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
  // const [passwordRightTemplate, setPasswordRightTemplate] = useState<string>('Show');
  // const [passwordTextFieldType, setPasswordTextFieldType] = useState<string>('password');

  // const changePasswordTextFieldType = () => {
  //   setPasswordRightTemplate(passwordRightTemplate === 'Show' ? 'Hide' : 'Show');
  //   setPasswordTextFieldType(passwordRightTemplate === 'Show' ? 'text' : 'password');
  // };

  // const onEmailChange = (value: string) => {
  //   setEmail(value);
  //   setEmailErrorMessage('');
  // };

  // const onPasswordChange = (value: string) => {
  //   setPassword(value);
  //   setPasswordErrorMessage('');
  // };

  // const handleLogin = async () => {
  //   if (wrongCredentialsToastId) {
  //     ToastHelper.removeToastMessage(wrongCredentialsToastId);
  //   }

  //   if (!email) {
  //     setEmailErrorMessage('The email address is required');
  //     return;
  //   }

  //   if (!password) {
  //     setPasswordErrorMessage('The password is required');
  //     return;
  //   }

  //   const errorCallback = () => {
  //     setWrongCredentialsToastId(ToastHelper.addToastMessage({ message: 'The email or password provided is incorrect' }));
  //   };

  //   login(email, password, undefined, errorCallback);
  // };

  // const onLoginClick = () => {
  //   handleLogin();
  // };

  // const onKeypress = (event: KeyboardEvent) => {
  //   if (event.key === 'Enter') {
  //     handleLogin();
  //   }
  // };

  return (
    <div className="sc-paper">
      <Box boxShadow={2} borderRadius={3} px={3} className="sc-login-box">
        <Grid container justifyContent="center" alignItems="center" mt={4} mb={2}>
          <img src="logo-sercom-vertical.png" alt="Sercom" width="100px" />
        </Grid>
        {/* <Box mt={4}>
            <Lock color='primary' fontSize="large" />
          </Box> */}
        <Grid item>
          <Stack alignItems="center" justifyContent="center" spacing={1}>
            <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h4' : 'h3'}>
              {t('SignIn')}
            </Typography>
            <Typography variant="caption" fontSize="14px" textAlign={matchDownSM ? 'center' : 'inherit'}>
              {t('EnterCredentialsContinue')}
            </Typography>
          </Stack>
        </Grid>
        {/* <Box pt={4} pb={2} style={{ width: '100%' }}>
          <GoogleLogin
            clientId={SercomConstants.GOOGLE_CLIENT_ID}
            buttonText="Continue with Google"
            className="sc-google-login"
            onSuccess={onGoogleSuccess}
            onFailure={onGoogleFailure}
            cookiePolicy={'single_host_origin'}
          />
        </Box> */}
        {/* <Box style={{ width: '100%' }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
            <Button
              variant="outlined"
              sx={{
                cursor: 'unset',
                minWidth: '30px',
                m: 2,
                py: 0.5,
                px: 1,
                borderColor: `${theme.palette.grey[300]} !important`,
                color: `${theme.palette.grey[900]}!important`,
                fontWeight: 500,
                borderRadius: SercomConstants.BORDER_RADIUS_LARGE,
              }}
              disableRipple
              disabled
            >
              OR
            </Button>
            <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
          </Box>
          <Grid item xs={12} container alignItems="center" justifyContent="center">
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Sign in with Email address</Typography>
            </Box>
          </Grid>
        </Box> */}
        <Box pt={4} pb={2} style={{ width: '100%' }}>
          <Box component="form" role="form">
            <Box mb={2}>
              <TextField
                size="small"
                type="email"
                label={t('Email')}
                fullWidth
                error={emailError}
                onChange={(event: object) => onEmailChange(event)}
                onKeyDown={onLoginKeyDown}
              />
            </Box>
            <Box mb={2}>
              <TextField
                size="small"
                type={showPassword ? 'text' : 'password'}
                label={t('Password')}
                fullWidth
                onChange={(event: object) => onPasswordChange(event)}
                error={passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onShowHidePassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={onLoginKeyDown}
              />
            </Box>
            <Box sx={{ textAlign: 'end' }}>
              <SercomLink text={t('ForgotPassword')} onClick={onForgotPassword}></SercomLink>
            </Box>
            <Box mt={4} mb={1}>
              <Button variant="contained" color="primary" fullWidth onClick={() => onLoginClick()}>
                {t('SignIn')}
              </Button>
            </Box>
            {/* <Box mt={2} mb={2}>
              <Divider />
            </Box> */}
            {/* <Box mt={2} mb={1} textAlign="center">
              <Typography component={Link} to="/register" variant="subtitle1" sx={{ textDecoration: 'none' }}>
                Don&apos;t have an account?
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      login: metadataActions.login,
      googleLogin: metadataActions.googleLogin,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(LoginPage);

