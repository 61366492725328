import { Button, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { SercomTypes } from '../support/sercom-types';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SercomConstants from '../support/sercom-constants';
import { Close } from '@mui/icons-material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const SercomUploadFile = (props: SercomTypes.SercomUploadFile) => {
  const [selectedFile, setSelectedFile] = useState<string | null>(null);

  const onFileSelected = (event: any) => {
    setSelectedFile(event?.target?.files[0]);
    props.onClick(event);
  };
  const onClear = () => {
    // if (importRef?.current) {
    //   importRef.current.value = null;
    setSelectedFile(null);
    if (props.onClear) {
      props.onClear();
    }
    // }
  };

  return (
    <Stack direction="row">
      <Button component="label" variant={props.variant} startIcon={<CloudUploadIcon />} sx={{ width: 180 }}>
        {props.text}
        <VisuallyHiddenInput type="file" accept={props.accept ?? '*.*'} onChange={onFileSelected} />
      </Button>
      {selectedFile && props.onClear && (
        <Typography sx={{ alignSelf: 'center', ml: 3 }} variant="body1">
          {selectedFile.name ?? ''}
        </Typography>
      )}
      {selectedFile && props.onClear && (
        <IconButton component="label" onClick={onClear}>
          <Close sx={{ color: SercomConstants.Color.NEGATIVE, width: 20 }} />
        </IconButton>
      )}
    </Stack>
  );
};

export default SercomUploadFile;

