const SercomConstants = {
  DEFAULT_DECIMAL_MAX: 1000000000,
  NONE_SELECT_VALUE: '0000',
  DEFAULT_TRIAL_DAYS: 14,
  IMPORT_ROWS_MAX: 500,

  DEFAULT_PAGE_SIZE: 15,

  BORDER_RADIUS_SMALL: '5px',
  BORDER_RADIUS_MEDIUM: '8px',
  BORDER_RADIUS_LARGE: '20px',

  GOOGLE_CLIENT_ID: '641981710258-d36bepce8pcpc7obhce1dejqd6mm8nrh.apps.googleusercontent.com',

  BORDER_RADIUS: '5px',
  EventColor: {
    DISABLED: '#999999',
    REQUEST: '#E8AA42',
    QUOTE: '#7C9070',
    JOB: '#6FA8DC',
  },
  Color: {
    PRIMARYBUTTON: '#eddbc7',
    PRIMARYLIGHT: '#f8fafc',
    PRIMARYMAIN: '#495579',
    PRIMARYDARK: '#2f3d68',
    SECONDARYLIGHT: '#eef2f6',
    HOVERMENU: '#CDD3DD',

    SERCOM_RED: '#A7727D',

    INPUT_BORDER: '#BDBDBD',

    // Primary
    PRIMARY: '#2f3d68',
    NEGATIVE: '#F44336',
    POSITIVE: '#4CAF50',

    // Surface
    SURFACE_1: '#FFFFFF',
    SURFACE_2: '#FAFAFA',
    SURFACE_3: '#F5F5F5',
    SURFACE_4: '#DBDBDB',
    SURFACE_5: '#212121',

    // Typeface
    TYPEFACE_1: '#FCFCFC',
    TYPEFACE_2: '#CCCCCC',
    TYPEFACE_3: '#888888',
    TYPEFACE_4: '#666666',
    TYPEFACE_5: '#444444',
    TYPEFACE_6: '#111111',

    // Box-shadow
    BOX_SHADOW_4: '0px 3px 6px #2121210A', // Opacity 0.04
    BOX_SHADOW_8: '0px 3px 6px #21212114', // Opacity 0.08
    BOX_SHADOW_12: '0px 3px 6px #2121211F', // Opacity 0.12
    BOX_SHADOW_16: '0px 3px 6px #21212129', // Opacity 0.16
  },
  AVATAR_COLORS: [
    // '#7f9494',
    // '#cbc2af',
    // '#aea289',
    // '#9298ad',
    // '#aebab0',
    // '#859388',
    // '#cfbabc',
    // '#b19799',
    // '#b7bccc',
    // '#cfbabc',
    // '#b19799',
    // '#b7bccc',

    '#314e36',
    '#553d17',
    '#7b6629',
    '#562f32',
    '#243152',
    '#1f4e4e',
    '#504976',
    '#5a4267',
    '#25445c',
    '#495579',
    '#152b2b',
    '#7e5356',
  ],
  DateFormat: {
    SYNCFUSION_DEFAULT: 'dd/MM/yyyy',
    MOMENT_DEFAULT: 'DD/MM/YYYY',
    SYNCFUSION_ISO: 'yyyy-MM-dd',
    ISO: 'YYYY-MM-DD',
  },
  DateTimeFormat: {
    SYNCFUSION_ISO: 'yyyy-MM-dd HH:mm',
    ISO: 'YYYY-MM-DD HH:mm',
  },
};

export default SercomConstants;

