import { StorageService } from '../services';
import SercomEnums from './sercom-enums';
import { SercomTypes } from './sercom-types';

const SercomHelper = {
  DATABASE_VERSION: 2,
  PLATFORM: 3, // Web

  DEFAULT_PAGE_SIZE: 15,

  BORDER_RADIUS_SMALL: '5px',
  BORDER_RADIUS_MEDIUM: '8px',
  BORDER_RADIUS_LARGE: '20px',

  GOOGLE_CLIENT_ID: '641981710258-d36bepce8pcpc7obhce1dejqd6mm8nrh.apps.googleusercontent.com',

  stringToDecimal: (value: string) => {
    let decimal = Number.parseFloat(value);
    if (isNaN(decimal) || decimal < 0) decimal = 0;
    return decimal;
  },
  displayDecimal: (value?: number) => {
    return value ? value.toFixed(2).toString() : '0.00';
  },
  displayFormattedAmountWithCurrency(value: number /* , currency?: string */) {
    return `$${SercomHelper.displayDecimal(value)}`;
  },
  getLanguageCode: (code: string): string => {
    const languages: Record<string, string> = {
      'fr': 'fr-CA',
      'fr-FR': 'fr-CA',
      'fr-CA': 'fr-CA',
      'en-US': 'en-US',
    };

    return languages[code] || languages['en-US'];
  },
  // getViewPages: (memberPermission: SercomEnums.MemberPermission): SercomTypes.ViewPages => {
  getViewPages: (): SercomTypes.ViewPages => {
    const memberPermission = StorageService.getPermission();
    const viewPages: SercomTypes.ViewPages = {
      viewDashboardActions: false,
      viewDashboardStatistics: false,
      viewDashboardFinances: false,
      viewRequest: false,
      viewQuote: false,
      viewJob: false,
      viewClient: false,
      viewSubscription: false,
      viewItem: false,
      viewMember: false,
      viewSettings: false,
      viewTax: false,
      viewScheduler: false,
      viewRoutes: false,
      manageRequest: false,
      manageQuote: false,
      manageJob: false,
      manageClient: false,
      manageSubscription: false,
      manageItem: false,
      manageTax: false,
      manageMember: false,
      manageIntegration: false,
    };
    switch (memberPermission) {
      case SercomEnums.MemberPermission.Admin:
        viewPages.viewDashboardActions = true;
        viewPages.viewDashboardStatistics = true;
        viewPages.viewDashboardFinances = true;
        viewPages.viewRoutes = true;
        viewPages.viewRequest = true;
        viewPages.viewQuote = true;
        viewPages.viewJob = true;
        viewPages.viewClient = true;
        viewPages.viewSubscription = true;
        viewPages.viewItem = true;
        viewPages.viewMember = true;
        viewPages.viewSettings = true;
        viewPages.viewScheduler = true;
        viewPages.viewRoutes = true;
        viewPages.manageRequest = true;
        viewPages.manageQuote = true;
        viewPages.manageJob = true;
        viewPages.manageClient = true;
        viewPages.manageItem = true;
        viewPages.manageSubscription = true;
        viewPages.manageTax = true;
        viewPages.manageMember = true;
        viewPages.manageIntegration = true;
        break;
      case SercomEnums.MemberPermission.Manager:
        viewPages.viewDashboardActions = true;
        viewPages.viewDashboardStatistics = true;
        viewPages.viewDashboardFinances = true;
        viewPages.viewRequest = true;
        viewPages.viewQuote = true;
        viewPages.viewJob = true;
        viewPages.viewClient = true;
        viewPages.viewItem = true;
        viewPages.viewMember = true;
        viewPages.viewSettings = true;
        viewPages.viewScheduler = true;
        viewPages.viewRoutes = true;
        viewPages.manageRequest = true;
        viewPages.manageQuote = true;
        viewPages.manageJob = true;
        viewPages.manageClient = true;
        break;
      case SercomEnums.MemberPermission.Employee:
        viewPages.viewDashboardActions = true;
        viewPages.viewRequest = true;
        viewPages.viewJob = true;
        viewPages.viewScheduler = true;
        viewPages.viewRoutes = true;
        break;
    }
    return viewPages;
  },
};

export default SercomHelper;

