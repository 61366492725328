import APIRequestHelper from '../helpers/api-request-helper';
import { SercomTypes } from '../support/sercom-types';

const LeadService = {
  async getLeads() {
    return APIRequestHelper.sendRequest('Get', 'adminLeads', false, null);
  },
  async getLead(id: string) {
    return APIRequestHelper.sendRequest('Get', `adminLeads/${id}`, false, null);
  },
  async getData() {
    return APIRequestHelper.sendRequest('Get', 'adminLeads/get_data?weeks=6', false, null);
  },
  async sendFirstContactEmails() {
    return APIRequestHelper.sendRequest('GET', 'adminLeads/send_first_contact_emails?count=20', false);
  },
  async sendFollowupEmails() {
    return APIRequestHelper.sendRequest('GET', 'adminLeads/send_followup_emails?count=20&weeks=6', false);
  },
  async saveLead(lead: SercomTypes.Lead) {
    const payload = {
      id: lead.id,
      name: lead.name,
      phone: lead.phone,
      email: lead.email,
      note: lead.note,
      address: lead.address,
      category: lead.category,
      source: lead.source,
      language: lead.language,
      url: lead.url,
      firstContactDate: lead.firstContactDate,
      followup1Date: lead.followup1Date,
      followup2Date: lead.followup2Date,
      status: lead.status,
    };
    return APIRequestHelper.sendRequest(lead.id && lead.id !== '' ? 'PUT' : 'POST', 'adminLeads', false, payload);
  },
  async deleteLead(id: string) {
    return APIRequestHelper.sendRequest('DELETE', `adminLeads/${id}`, false, null);
  },
};
export default LeadService;

