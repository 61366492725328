import SercomEnums from '../support/sercom-enums';

const StorageService = {
  getToken(): null | string {
    return localStorage.getItem('i6fzvvklnz') ?? null;
  },
  setToken(token: string) {
    return localStorage.setItem('i6fzvvklnz', token);
  },
  getCurrentName(): null | string {
    return localStorage.getItem('baatvyim89') ?? null;
  },
  setCurrentName(name: string) {
    return localStorage.setItem('baatvyim89', name);
  },
  getCurrentCompanyId(): null | string {
    return localStorage.getItem('2obiie1kxd') ?? null;
  },
  setCurrentCompanyId(id: string) {
    localStorage.setItem('2obiie1kxd', id);
  },
  getCurrentUserId(): null | string {
    const userId = localStorage.getItem('wqc90ypgsl');
    return userId ?? null;
  },
  setCurrentUserId(id: string) {
    localStorage.setItem('wqc90ypgsl', id);
  },
  setForgotPasswordValue(value: string) {
    localStorage.setItem('awsd039fsm', value);
  },
  getForgotPasswordValue(): null | string {
    const value = localStorage.getItem('awsd039fsm');
    return value ?? null;
  },
  getPermission(): SercomEnums.MemberPermission {
    const permission = localStorage.getItem('8a4097ctix');
    return permission ? (parseInt(permission) as SercomEnums.MemberPermission) : SercomEnums.MemberPermission.Employee;
  },
  setPermission(permission: SercomEnums.MemberPermission) {
    localStorage.setItem('8a4097ctix', permission.toString());
  },
  getLanguage(): null | SercomEnums.Language {
    const language = localStorage.getItem('otj58xfb46');
    return language ? (parseInt(language) as SercomEnums.Language) : SercomEnums.Language.English;
  },
  setLanguage(language: SercomEnums.Language) {
    localStorage.setItem('otj58xfb46', language.toString());
  },
  getDisplayMode(): null | SercomEnums.DisplayMode {
    const displayMode = localStorage.getItem('qbr9gkptuv');
    return displayMode ? (parseInt(displayMode) as SercomEnums.DisplayMode) : null;
  },
  setDisplayMode(displayMode: SercomEnums.DisplayMode) {
    localStorage.setItem('qbr9gkptuv', displayMode.toString());
  },
  clearAll() {
    localStorage.removeItem('i6fzvvklnz');
    localStorage.removeItem('8gvfk3g3jm');
    localStorage.removeItem('baatvyim89');
    localStorage.removeItem('wqc90ypgsl');
    localStorage.removeItem('2obiie1kxd');
    localStorage.removeItem('3fxnyda5u1');
    localStorage.removeItem('qbr9gkptuv');
    localStorage.removeItem('8a4097ctix');
    localStorage.removeItem('otj58xfb46');
    localStorage.removeItem('awsd039fsm');
  },
};

export default StorageService;

