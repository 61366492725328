import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { SercomTypes } from '../support/sercom-types';
import { Box, Button, Fade, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SercomConstants from '../support/sercom-constants';

const SercomMenu = (props: SercomTypes.SouvlakiMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const onItemClick = (action: any) => {
  //   handleClose();
  //   action;
  // };

  return (
    <Box>
      {props.text ? (
        <Button title={props.text} variant="outlined" onClick={onClick} sx={{ textTransform: 'none' }}>
          {props.text}
        </Button>
      ) : (
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={onClick}
        >
          {props.icon ? props.icon : <MoreVertIcon />}
        </IconButton>
      )}
      <Menu
        id="fade-menu"
        // MenuListProps={{
        //   'aria-labelledby': 'fade-button',
        // }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        PaperProps={{
          // elevation: 0,
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: '20ch',
          },
          // sx: {
          //   'overflow': 'visible',
          //   'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          //   'mt': 1.5,
          //   '& .MuiAvatar-root': {
          //     width: 32,
          //     height: 32,
          //     ml: -0.5,
          //     mr: 1,
          //   },
          //   '&:before': {
          //     content: '""',
          //     display: 'block',
          //     position: 'absolute',
          //     top: 0,
          //     right: 14,
          //     width: 10,
          //     height: 10,
          //     bgcolor: 'background.paper',
          //     transform: 'translateY(-50%) rotate(45deg)',
          //     zIndex: 0,
          //   },
          // },
        }}
        // PaperProps={{
        //   elevation: 0,
        //   sx: {
        //     'overflow': 'visible',
        //     'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        //     'mt': 1.5,
        //     '& .MuiAvatar-root': {
        //       width: 32,
        //       height: 32,
        //       ml: -0.5,
        //       mr: 1,
        //     },
        //     '&:before': {
        //       content: '""',
        //       display: 'block',
        //       position: 'absolute',
        //       top: 0,
        //       right: 14,
        //       width: 10,
        //       height: 10,
        //       bgcolor: 'background.paper',
        //       transform: 'translateY(-50%) rotate(45deg)',
        //       zIndex: 0,
        //     },
        //   },
        // }}
        // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {props.items.map((menuItem, index) => {
          // if (menuItem.separator) {
          //   return <MenuDivider key={index} />;
          // }
          return (
            <MenuItem
              key={index}
              onClick={() => {
                handleClose();
                menuItem.action();
              }}
            >
              <Box color={menuItem.isDestructive ? SercomConstants.Color.NEGATIVE : SercomConstants.Color.PRIMARYMAIN}>
                {menuItem.icon ? menuItem.icon : <div />}
              </Box>
              <Box color={menuItem.isDestructive ? SercomConstants.Color.NEGATIVE : SercomConstants.Color.PRIMARYMAIN}>{menuItem.text}</Box>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default SercomMenu;

