import storeHelper from '../../helpers/store-helper';
import { metadataThunks } from './metadata-thunks';

const metadataActionTypePrefix = 'metadata/';

export const metadataActions = {
  login: storeHelper.createThunk(`${metadataActionTypePrefix}login`, metadataThunks.login),
  register: storeHelper.createThunk(`${metadataActionTypePrefix}register`, metadataThunks.register),
  createCompany: storeHelper.createThunk(`${metadataActionTypePrefix}createCompany`, metadataThunks.register),
  googleLogin: storeHelper.createThunk(`${metadataActionTypePrefix}googleLogin`, metadataThunks.googleLogin),
  logout: storeHelper.createThunk(`${metadataActionTypePrefix}logout`, metadataThunks.logout),
  getLoggedInUserData: storeHelper.createThunk(`${metadataActionTypePrefix}getLoggedInUserData`, metadataThunks.getLoggedInUserData),
  setDisplayMode: storeHelper.createThunk(`${metadataActionTypePrefix}setDisplayMode`, metadataThunks.setDisplayMode),
  getDisplayMode: storeHelper.createThunk(`${metadataActionTypePrefix}getDisplayMode`, metadataThunks.getDisplayMode),
  setCurrentEvent: storeHelper.createThunk(`${metadataActionTypePrefix}setCurrentEvent`, metadataThunks.setCurrentEvent),
  removeCurrentEvent: storeHelper.createThunk(`${metadataActionTypePrefix}removeCurrentEvent`, metadataThunks.removeCurrentEvent),
  setLanguage: storeHelper.createThunk(`${metadataActionTypePrefix}setLanguage`, metadataThunks.setLanguage),
};

