import { DataManager, UrlAdaptor } from '@syncfusion/ej2-data';
import { StorageService } from '../services';

const BASE_URI = 'https://api.sercom.io/api'; // Live
// const BASE_URI = 'https://localhost:7060/api'; // Debug
const httpStatus = {
  OK: 200,
  Created: 201,
  Accepted: 202,
  NoContent: 204,
};

export interface APIResponse {
  data: any;
  isSuccess: boolean;
  statusCode: number;
}

const APIRequestHelper = {
  async sendRequest(httpMethod: string, action: string, anonymous: boolean, payload?: string | {} | null, isFile?: boolean | null) {
    const requestHeaders = new Headers();
    if (!isFile) {
      requestHeaders.set('Content-Type', isFile ? 'multipart/form-data' : 'application/json');
      requestHeaders.set('Accept', 'application/json');
    }
    requestHeaders.set('ApiKey', 'c41eea85-3070-46e1-8d6a-0580b1f670a7'); // TODO: Generate a new API Key for WEB

    if (!anonymous) {
      const token = StorageService.getToken();
      const companyId = StorageService.getCurrentCompanyId();
      if (token == '' || token == null || companyId == '' || companyId == null) {
        return {
          data: '',
          isSuccess: false,
        };
      }
      requestHeaders.set('Authorization', `Bearer ${token}`);
      requestHeaders.set('company_id', companyId);
    }

    const url = `${BASE_URI}/${action}`;
    const fetchParameters: RequestInit = {
      method: httpMethod,
      headers: requestHeaders,
      // body: JSON.stringify(payload),
    };
    if (payload) {
      if (isFile) {
        fetchParameters.body = payload as string;
      } else {
        fetchParameters.body = JSON.stringify(payload);
      }
    }
    const result = await fetch(url, fetchParameters);
    const response = await result.json();

    return {
      data: response,
      StatusCode: result.status,
      isSuccess:
        result.status === httpStatus.OK ||
        result.status === httpStatus.Created ||
        result.status === httpStatus.Accepted ||
        result.status === httpStatus.NoContent,
    };
  },

  async sendDeleteRequest(action: string) {
    const url = `${BASE_URI}/${action}`;
    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Accept', 'application/json');
    requestHeaders.set('ApiKey', 'c41eea85-3070-46e1-8d6a-0580b1f670a7'); // TODO: Generate a new API Key for WEB

    const token = StorageService.getToken();
    const companyId = StorageService.getCurrentCompanyId();
    if (token == '' || token == null || companyId == '' || companyId == null) {
      return {
        data: '',
        isSuccess: false,
      };
    }
    requestHeaders.set('Authorization', `Bearer ${token}`);
    requestHeaders.set('company_id', companyId);

    const fetchParameters = {
      method: 'DELETE',
      headers: requestHeaders,
    };

    const result = await fetch(url, fetchParameters);
    const response = await result.json();
    return {
      data: response,
      StatusCode: result.status,
      isSuccess:
        result.status === httpStatus.OK ||
        result.status === httpStatus.Created ||
        result.status === httpStatus.Accepted ||
        result.status === httpStatus.NoContent,
    };
  },

  async login(username: string, password: string) {
    const result = await fetch(`${BASE_URI}/authenticate/login`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Accept': 'application/json',
        'ApiKey': 'c41eea85-3070-46e1-8d6a-0580b1f670a7',
      }, // TODO: use a different API-KEY?
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    const response = await result.json();
    let apiResponse: APIResponse = { data: null, statusCode: httpStatus.NoContent, isSuccess: false };

    if (response && response.token) {
      apiResponse = {
        data: response,
        statusCode: result.status,
        isSuccess:
          result.status === httpStatus.OK ||
          result.status === httpStatus.Created ||
          result.status === httpStatus.Accepted ||
          result.status === httpStatus.NoContent,
      };
    }
    return apiResponse;
  },
  async getDataManager(httpMethod: string, action: string) {
    const token = StorageService.getToken();
    const companyId = StorageService.getCurrentCompanyId();
    const url = `${BASE_URI}/${action}`;
    const headers = [
      {
        'content-type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'ApiKey': 'c41eea85-3070-46e1-8d6a-0580b1f670a7',
        'company_id': companyId,
      },
    ];
    try {
      return new DataManager({
        requestType: httpMethod,
        url,
        adaptor: new UrlAdaptor(),
        crossDomain: true,
        offline: false,
        headers: headers,
      });
    } catch (error) {
      // console.log(error);
    }
  },
  // async getCoordinatesFromPlaceId(placeId: string) {
  //   try {
  //     const response = await fetch(
  //       `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${SercomConstants.GOOGLE_MAPS_PLACE_ID_API_KEY}`
  //     );
  //     if (!response.ok) {
  //       throw new Error('Error fetching data from Google Maps Geocoding API');
  //     }

  //     const data = await response.json();
  //     if (data.results.length > 0) {
  //       const location = data.results[0].geometry.location;
  //       return {
  //         lat: location.lat,
  //         lng: location.lng,
  //       };
  //     } else {
  //       throw new Error('No results found for the provided placeId');
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // },
};

export default APIRequestHelper;

