import APIRequestHelper from '../helpers/api-request-helper';
import SercomEnums from '../support/sercom-enums';

const gridCustomizationService = {
  async getGridCustomization(gridType: SercomEnums.GridCustomizationType) {
    const response = await APIRequestHelper.sendRequest('GET', `gridcustomizations/${gridType}`, false, null);

    // console.log('gridCustomizationService() - response', response);
    if (response.isSuccess && response.data) {
      const data = response.data;
      data.value = response.data.value; // JSON.parse(response.data.value);
      return data;
    }
    return null;
  },

  async saveGridCustomization(gridCustomization: any) {
    // console.log('saveGridCustomization: ', gridCustomization);
    const payload = {
      grid_customization: {
        type: gridCustomization.type,
        value: JSON.stringify(gridCustomization.value),
      },
    };

    const response = await APIRequestHelper.sendRequest('PUT', 'gridcustomizations', false, payload);

    if (response.isSuccess) {
      // console.log('saveGridCustomization - response.data', response.data);
      const data = response.data;
      data.value = response.data.value;
      return data;
    }
    return null;
  },

  async resetGridCustomization(gridType: SercomEnums.GridCustomizationType) {
    const response = await APIRequestHelper.sendRequest('DELETE', `gridcustomizations/${gridType}`, false, null);
    return response.isSuccess;
  },
};

export default gridCustomizationService;
