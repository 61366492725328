import { createReducer } from '@reduxjs/toolkit';
import SercomEnums from '../../support/sercom-enums';
import { metadataActions } from './metadata-actions';
import { SercomTypes } from '../../support/sercom-types';
import { StorageService } from '../../services';

type MetaDataState = {
  currentUser: {
    isLogged: boolean;
    companyId: null | number;
    permission: number;
    language: number;
  };
  currentCompany: {
    id: number;
    name: string;
  };
  currentEvent: SercomTypes.Event | null;
  displayMode: SercomEnums.DisplayMode;
};

const initialMetadataState: MetaDataState = {
  currentUser: {
    isLogged: false,
    companyId: null,
    permission: SercomEnums.MemberPermission.Employee,
    language: SercomEnums.Language.English,
  },
  currentCompany: {
    id: 0,
    name: '',
  },
  currentEvent: null,
  displayMode: SercomEnums.DisplayMode.Light,
};

export const metadataReducer = createReducer(initialMetadataState, (builder) => {
  builder
    .addCase(metadataActions.logout.success, (state: MetaDataState) => {
      state.currentUser = initialMetadataState.currentUser;
    })
    .addCase(metadataActions.getLoggedInUserData.success, (state: MetaDataState, action: any) => {
      const { isLogged, companyId, permission, language } = action.payload;

      state.currentUser = {
        isLogged: isLogged ?? initialMetadataState.currentUser.isLogged,
        companyId: companyId ?? initialMetadataState.currentUser.companyId,
        permission: permission ?? initialMetadataState.currentUser.permission,
        language: language ?? initialMetadataState.currentUser.language,
      };
    })
    .addCase(metadataActions.login.success, (state: MetaDataState, action: any) => {
      const { token, companyId, permission, language } = action.payload;

      state.currentUser = {
        isLogged: token !== null && token !== '' && token !== undefined,
        companyId: companyId ?? initialMetadataState.currentUser.companyId,
        permission: permission ?? initialMetadataState.currentUser.permission,
        language: language ?? initialMetadataState.currentUser.language,
      };
    })
    .addCase(metadataActions.register.success, (state: MetaDataState, action: any) => {
      const { token, companyId, language } = action.payload;

      state.currentUser = {
        isLogged: token !== null && token !== '' && token !== undefined,
        companyId: companyId ?? initialMetadataState.currentUser.companyId,
        permission: SercomEnums.MemberPermission.Employee, // TODO: Keep it with the lowest permissions profile
        language: language ?? initialMetadataState.currentUser.language,
      };
    })
    .addCase(metadataActions.googleLogin.success, (state: MetaDataState, action: any) => {
      const { token, companyId, permission, language } = action.payload;

      state.currentUser = {
        isLogged: token !== null && token !== '' && token !== undefined,
        companyId: companyId ?? initialMetadataState.currentUser.companyId,
        permission: permission ?? initialMetadataState.currentUser.permission,
        language: language ?? initialMetadataState.currentUser.language,
      };
    })
    .addCase(metadataActions.getLoggedInUserData.error, (state: MetaDataState) => {
      state.currentUser = initialMetadataState.currentUser;
    })
    .addCase(metadataActions.setDisplayMode.success, (state: MetaDataState, action: any) => {
      const { displayMode } = action.payload;

      state.displayMode = displayMode;
    })
    .addCase(metadataActions.setLanguage.success, (state: MetaDataState, action: any) => {
      const { language } = action.payload;

      StorageService.setLanguage(language);
      state.currentUser.language = language;
    })
    .addCase(metadataActions.createCompany.success, (state: MetaDataState, action: any) => {
      const { company } = action.payload;

      state.currentCompany = {
        id: company.id,
        name: company.name,
      };
    })
    .addCase(metadataActions.setDisplayMode.error, (state: MetaDataState) => {
      state.displayMode = initialMetadataState.displayMode;
    })
    // .addCase(metadataActions.getCurrentEvent.success, (state: MetaDataState, action: any) => {
    //   state.currentEvent = action.payload;
    // })
    .addCase(metadataActions.removeCurrentEvent.success, (state: MetaDataState) => {
      state.currentEvent = null;
    })
    .addCase(metadataActions.setCurrentEvent.success, (state: MetaDataState, action: any) => {
      const { currentEvent } = action.payload;
      state.currentEvent = currentEvent.data;
    });
});

