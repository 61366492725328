import { TextField } from '@mui/material';
import React from 'react';
import { SercomTypes } from '../support/sercom-types';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import SercomConstants from '../support/sercom-constants';

// const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
//   const { onChange, ...other } = props;
//   return (
//     <IMaskInput
//       {...other}
//       mask="(#00) 000-0000"
//       definitions={{
//         '#': /[1-9]/,
//       }}
//       inputRef={ref}
//       onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
//       overwrite
//     />
//   );
// });

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  decimalScale?: number;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isAllowed={(values) => {
        const { floatValue } = values;
        return !floatValue ? true : floatValue < SercomConstants.DEFAULT_DECIMAL_MAX;
      }}
      thousandSeparator
      // fixedDecimalScale
      valueIsNumericString
      decimalScale={props.decimalScale ?? 2}
      // prefix="$"
    />
  );
});
const SercomNumericField = (props: SercomTypes.SercomNumericField) => {
  const title = props.title.concat(props.isRequired ? '*' : '');
  return (
    <TextField
      // sx={{ m: 1 }}
      name={props.name ?? undefined}
      fullWidth
      disabled={props.disabled ?? false}
      error={props.error ?? false}
      helperText={props.helperText ?? ''}
      label={title}
      size="small"
      value={props.value}
      variant={props.isStandard ? 'standard' : 'outlined'}
      onChange={props.onChange}
      // id="formatted-numberformat-input"
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        inputProps: { decimalScale: props.decimalScale },
      }}
    />
  );
};

export default SercomNumericField;
