import React from 'react';
import styled from 'styled-components';
import SercomConstants from '../../support/sercom-constants';

const DefaultGridNoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const DefaultGridNoDataImage = styled.img`
  margin-bottom: 30px;
  max-width: 125px;
`;

const DefaultGridNoDataTitle = styled.div`
  font-size: 20px;
  color: ${SercomConstants.Color.TYPEFACE_5};
`;

const DefaultGridNoDataText = styled.div`
  margin-top: 20px;
  font-size: 12px;
  color: ${SercomConstants.Color.TYPEFACE_5};
`;

const DefaultNoDataTemplate = (
  <DefaultGridNoDataContainer>
    <DefaultGridNoDataImage />
    <DefaultGridNoDataTitle>No data</DefaultGridNoDataTitle>
    <DefaultGridNoDataText>Could not find any record</DefaultGridNoDataText>
  </DefaultGridNoDataContainer>
);

export default DefaultNoDataTemplate;
