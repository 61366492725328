import React, { useEffect, useRef, useState } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import SercomGrid from '../../components/Grid';
import { DataManager } from '@syncfusion/ej2-data';
import { GridComponent, RecordClickEventArgs, SortSettingsModel } from '@syncfusion/ej2-react-grids';
import SercomEnums from '../../support/sercom-enums';
import { useTranslation } from 'react-i18next';
import AdminService from '../../services/admin-service';
import SercomConstants from '../../support/sercom-constants';
import ImportFile from './ImportFile';
import SercomButton from '../../components/SercomButton';
import snackbarHelper from '../../helpers/snackbar-helper';
import SercomMenu from '../../components/SercomMenu';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { SercomTypes } from '../../support/sercom-types';
import { useNavigate } from 'react-router-dom';
import { usePopupManager } from 'react-popup-manager';
import SercomConfirmationDialog from '../../components/SercomConfirmationDialog';
import LeadService from '../../services/lead-service';
import LeadPopup from './LeadPopup';

const LeadsPage = () => {
  const [dataSource, setDataSource] = useState<DataManager | undefined>(undefined);
  const leadGridRef = useRef<GridComponent | null>(null);
  const [leadsData, setLeadsData] = useState<SercomTypes.LeadsData | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const popupManager = usePopupManager();

  const leadCategoryStrings: { [key: string]: string } = {
    [SercomEnums.LeadCategory.Plumbing]: t('Plumbing'),
    [SercomEnums.LeadCategory.Electricity]: t('Electricity'),
    [SercomEnums.LeadCategory.Paint]: t('Paint'),
    [SercomEnums.LeadCategory.HeatingAirConditioning]: t('HeatingAirConditioning'),
    [SercomEnums.LeadCategory.ChimneySweeping]: t('ChimneySweeping'),
    [SercomEnums.LeadCategory.WaterTreatment]: t('WaterTreatment'),
    [SercomEnums.LeadCategory.Gutter]: t('Gutter'),
    [SercomEnums.LeadCategory.DoorWindow]: t('DoorWindow'),
    [SercomEnums.LeadCategory.Flooring]: t('Flooring'),
    [SercomEnums.LeadCategory.GarageDoor]: t('GarageDoor'),
    [SercomEnums.LeadCategory.Exterminator]: t('Exterminator'),
  };
  const leadStatusStrings: { [key: string]: string } = {
    [SercomEnums.LeadStatus.Enabled]: t('Enabled'),
    [SercomEnums.LeadStatus.Disabled]: t('Disabled'),
    [SercomEnums.LeadStatus.Converted]: t('Converted'),
  };

  const getLeadCategoryEnumOptions = () =>
    Object.keys(leadCategoryStrings).map((key) => ({
      label: leadCategoryStrings[key],
      value: parseInt(key),
    }));
  const leadCategoryValueAccessor = (field: string, data: any) => leadCategoryStrings[data[field]];

  const getLeadStatusEnumOptions = () =>
    Object.keys(leadStatusStrings).map((key) => ({
      label: leadStatusStrings[key],
      value: parseInt(key),
    }));
  const leadStatusValueAccessor = (field: string, data: any) => leadStatusStrings[data[field]];

  useEffect(() => {
    async function init() {
      const dataManager = await AdminService.getLeadsDatasource();
      setDataSource(dataManager);
      const leadsData = await LeadService.getData();
      if (leadsData.isSuccess) {
        setLeadsData(leadsData.data);
      }
    }
    init();
  }, []);

  const onSendEmails = async (leadEmail: SercomEnums.LeadEmail) => {
    const result = await (leadEmail == SercomEnums.LeadEmail.FirstContact
      ? LeadService.sendFirstContactEmails()
      : LeadService.sendFollowupEmails());
    if (result.isSuccess && result.data != null) {
      leadGridRef.current?.refresh?.();
      snackbarHelper.showSuccess(t('LeadEmailsSentSuccessfully', { count: result.data }));
    } else {
      snackbarHelper.showErrorFromCode(SercomEnums.ErrorCode.ServerError, t);
    }
  };

  const displayDeleteConfirmation = (id: string) => {
    // onActionsMenuClose();
    popupManager.open(SercomConfirmationDialog, {
      message: t('DeleteThisLead'),
      action: () => {
        LeadService.deleteLead(id).then((result) => {
          if (result.isSuccess && result.data) {
            if (result.data.errorCode && result.data.errorCode != SercomEnums.ErrorCode.None) {
              snackbarHelper.showErrorFromCode(result.data.errorCode, t);
            } else {
              leadGridRef.current?.refresh?.();
              snackbarHelper.showSuccess(t('LeadDeletedSuccessfully'));
            }
          } else {
            snackbarHelper.showErrorFromCode(SercomEnums.ErrorCode.ServerError, t);
          }
        });
      },
      buttonText: t('Delete'),
      onClose: () => {},
    });
  };

  const openCreatePopup = (id?: string) => {
    popupManager.open(LeadPopup, {
      id: id,
      action: () => {
        leadGridRef.current?.refresh?.();
      },
      buttonText: id ? t('Update') : t('Create'),
      onClose: () => {},
    });
  };

  const getLeadData = (leadEmail: SercomEnums.LeadEmail, title: string, sent: number, available: number) => {
    return (
      <Paper elevation={2} sx={{ minWidth: 200, height: 220, backgroundColor: '#FFFFFF', borderRadius: 3, px: 2, py: 2, mb: 0.2, ml: 0.2 }}>
        <Stack direction="column" spacing={2} sx={{ alignItems: 'center' }}>
          <Typography variant="h5" pb={1} minWidth={100}>
            {t(title)}
          </Typography>
          <Stack sx={{ alignItems: 'center' }}>
            <Typography variant="subtitle1" sx={{ fontSize: 44 }}>
              {available}
            </Typography>
            {leadEmail == SercomEnums.LeadEmail.FirstContact ? (
              <SercomButton
                variant="contained"
                text={t('SendFirstContactEmails')}
                onClick={() => onSendEmails(SercomEnums.LeadEmail.FirstContact)}
              />
            ) : leadEmail == SercomEnums.LeadEmail.Followup1 ? (
              <SercomButton
                variant="contained"
                text={t('SendFollowupEmails')}
                onClick={() => onSendEmails(SercomEnums.LeadEmail.Followup1)}
              />
            ) : (
              <Box height={36} />
            )}
          </Stack>
          <Typography variant="caption" sx={{ fontSize: 14 }}>
            {`${sent} sent`}
          </Typography>
        </Stack>
      </Paper>
    );
  };

  const getImport = () => {
    return (
      <Paper elevation={2} sx={{ minWidth: 200, height: 220, backgroundColor: '#FFFFFF', borderRadius: 3, px: 2, py: 2, mb: 0.2, ml: 0.2 }}>
        <Stack
          direction="column"
          spacing={2}
          sx={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h5" pb={1} minWidth={100}>
            {t('ImportLeads')}
          </Typography>
          <ImportFile
            type={SercomEnums.ImportType.Lead}
            maxRows={SercomConstants.IMPORT_ROWS_MAX}
            onImported={() => leadGridRef.current?.refresh?.()}
            columnView={true}
          />
        </Stack>
      </Paper>
    );
  };

  const getLeadsData = () => {
    return (
      leadsData && (
        <Stack direction="row" spacing={2} sx={{ alignSelf: 'end' }}>
          {getLeadData(
            SercomEnums.LeadEmail.FirstContact,
            'FirstContactEmails',
            leadsData.firstContactSent,
            leadsData.firstContactAvailable
          )}
          {getLeadData(SercomEnums.LeadEmail.Followup1, 'Followup1Emails', leadsData.followup1Sent, leadsData.followup1Available)}
          {getLeadData(SercomEnums.LeadEmail.Followup2, 'Followup2Emails', leadsData.followup2Sent, leadsData.followup2Available)}
          {getImport()}
        </Stack>
      )
    );
  };

  const rowMenuTemplate = (prop: any) => {
    const { Id } = prop;
    const items: SercomTypes.SouvlakiMenuItemProps[] = [
      {
        text: t('Edit'),
        icon: <EditOutlined sx={{ width: 20, mr: 2 }} />,
        action: () => {
          navigate(`/client/${Id}`);
        },
      },
      {
        text: t('Delete'),
        icon: <DeleteOutline sx={{ width: 20, mr: 2 }} />,
        isDestructive: true,
        action: () => {
          displayDeleteConfirmation(Id);
        },
      },
    ];

    return <SercomMenu items={items} />;
  };

  const menuColumn: any = rowMenuTemplate;

  const onRecordClick = (args: RecordClickEventArgs) => {
    if (!args.cell?.classList?.contains('e-templatecell')) {
      const rowData = args.rowData as { Id?: string } | undefined;
      const id = rowData?.Id;
      openCreatePopup(id);
    }
  };

  const columns = [
    { field: 'Id', type: 'string', headerText: 'Id', isPrimaryKey: true, visible: false, showInColumnChooser: false },
    { field: 'Name', type: 'string', headerText: t('Name'), visible: true, allowSearch: true },
    { field: 'Email', type: 'string', headerText: t('Email'), visible: true, allowSearch: true },
    { field: 'Phone', type: 'string', headerText: t('Phone'), visible: false, allowSearch: true },
    { field: 'Address', type: 'string', headerText: t('Address'), visible: false, allowSearch: true },
    { field: 'Url', type: 'string', headerText: t('Url'), visible: false, allowSearch: false },
    {
      field: 'CreationDate',
      type: 'date',
      headerText: t('CreationDate'),
      format: { type: 'date', format: SercomConstants.DateFormat.SYNCFUSION_ISO },
      visible: true,
    },
    {
      field: 'FirstContactDate',
      type: 'date',
      headerText: t('FirstContactDate'),
      format: { type: 'date', format: SercomConstants.DateFormat.SYNCFUSION_ISO },
      visible: true,
    },
    {
      field: 'Followup1Date',
      type: 'date',
      headerText: t('Followup1Date'),
      format: { type: 'date', format: SercomConstants.DateFormat.SYNCFUSION_ISO },
      visible: true,
    },
    {
      field: 'Followup2Date',
      type: 'date',
      headerText: t('Followup2Date'),
      format: { type: 'date', format: SercomConstants.DateFormat.SYNCFUSION_ISO },
      visible: false,
    },
    {
      field: 'Category',
      type: 'number',
      headerText: t('Category'),
      valueAccessor: leadCategoryValueAccessor,
      enumFilter: getLeadCategoryEnumOptions(),
      visible: true,
    },
    {
      field: 'Status',
      type: 'number',
      headerText: t('Status'),
      valueAccessor: leadStatusValueAccessor,
      enumFilter: getLeadStatusEnumOptions(),
      visible: true,
    },
    { field: 'Source', type: 'string', headerText: t('Source'), visible: false, allowSearch: true },
    { field: 'Note', type: 'string', headerText: t('Note'), visible: false, allowSearch: true },
    { headerText: '', template: menuColumn, width: 20, showInColumnChooser: false },
  ];
  const sortSettings: SortSettingsModel = { columns: [{ field: 'Name', direction: 'Ascending' }] };
  return dataSource ? (
    dataSource && (
      <Box sx={{ borderRadius: 2, p: 5, display: 'flex', flexDirection: 'column', background: '#ffffff' }}>
        <Typography variant="h3" sx={{ mb: 3 }}>
          {t('Leads')}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Box>
            <SercomButton text={t('CreateLead')} variant="contained" onClick={() => openCreatePopup()} />
          </Box>
          <Stack direction="column" spacing={2}>
            {getLeadsData()}
          </Stack>
        </Box>
        <SercomGrid
          gridRef={leadGridRef}
          dataSource={dataSource}
          columns={columns}
          gridCustomizationType={SercomEnums.GridCustomizationType.AdminLead}
          recordClick={onRecordClick}
          sortSettings={sortSettings}
        />
      </Box>
    )
  ) : (
    <div></div>
  );
};

export default LeadsPage;

