import styled, { css, keyframes } from 'styled-components';
import SercomConstants from '../../support/sercom-constants';

type SharedLoadingProps = {
  width: number;
  height: number;
};

const scale = keyframes`
  0% { background-position: 0% 51%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 51%; }
`;

const sharedStyles = css`
  background: linear-gradient(
    270deg,
    ${SercomConstants.Color.SURFACE_4},
    ${SercomConstants.Color.SURFACE_3},
    ${SercomConstants.Color.SURFACE_4}
  );
  background-size: 600% 600%;
  animation: ${scale} 2s ease infinite;
  display: inline-flex;
  flex-shrink: 0;
  max-width: 100%;
`;

export const LoadingRectangle = styled.div<SharedLoadingProps>`
  ${sharedStyles}
  width: ${(props: SharedLoadingProps) => props.width + 'px'};
  height: ${(props: SharedLoadingProps) => props.height + 'px'};
  border-radius: ${SercomConstants.BORDER_RADIUS};
`;

export const LoadingCircle = styled.div<SharedLoadingProps>`
  ${sharedStyles}
  width: ${(props: SharedLoadingProps) => props.width + 'px'};
  height: ${(props: SharedLoadingProps) => props.height + 'px'};
  border-radius: 50%;
`;
