import { loadCldr, L10n, setCulture } from '@syncfusion/ej2-base';
// import frGregorian from 'cldr-data/main/fr/ca-gregorian.json';
// import frNumbers from 'cldr-data/main/fr/numbers.json';
// import frTimeZoneNames from 'cldr-data/main/fr/timeZoneNames.json';
// import frCAGregorian from 'cldr-data/main/fr-CA/ca-gregorian.json';
// import frCANumbers from 'cldr-data/main/fr-CA/numbers.json';
// import frCATimeZoneNames from 'cldr-data/main/fr-CA/timeZoneNames.json';
// import enGregorian from 'cldr-data/main/en/ca-gregorian.json';
// import enNumbers from 'cldr-data/main/en/numbers.json';
// import enTimeZoneNames from 'cldr-data/main/en/timeZoneNames.json';

// import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
// import weekData from 'cldr-data/supplemental/weekData.json';
import i18n from '../i18n';
import SyncfusionConstants from '../support/syncfusion-constants';

function getLanguageCode(code: string): string {
  const languages: Record<string, string> = {
    'fr': 'fr',
    'fr-FR': 'fr',
    'fr-CA': 'fr',
    'en-US': 'en',
  };

  return languages[code] || languages['en-US'];
}

function syncfusionLocaleInit() {
  // const languages: Record<string, any> = {
  //   'fr': [frGregorian, frNumbers, frTimeZoneNames],
  //   'fr-FR': [frGregorian, frNumbers, frTimeZoneNames],
  //   'fr-CA': [frCAGregorian, frCANumbers, frCATimeZoneNames],
  //   'en-US': [enGregorian, enNumbers, enTimeZoneNames],
  // };
  // const language = languages[i18n.language] || languages['en-US'];
  // // const languageKey = getLanguageCode(i18n.language);
  // loadCldr(numberingSystems, weekData, ...language);
  loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/fr-CA/ca-gregorian.json'),
    require('cldr-data/main/fr-CA/numbers.json'),
    require('cldr-data/main/fr-CA/timeZoneNames.json'),
    require('cldr-data/supplemental/weekData.json')
  );
  L10n.load(SyncfusionConstants.TRANSLATION);
  // L10n.load({ [languageKey]: i18n.t('SyncfusionGlobalization', { returnObjects: true }) });
  setCulture(i18n.resolvedLanguage ?? 'en');
}

export { syncfusionLocaleInit, getLanguageCode };
