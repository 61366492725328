import { TextField } from '@mui/material';
import React from 'react';
import { SercomTypes } from '../support/sercom-types';

const SercomTextField = (props: SercomTypes.SercomTextField) => {
  const title = props.title.concat(props.isRequired ? '*' : '');
  return (
    <TextField
      // sx={{ m: 1 }}
      name={props.name ?? undefined}
      fullWidth
      error={props.error ?? false}
      helperText={props.helperText ?? ''}
      label={title}
      size="small"
      disabled={props.disabled ?? false}
      value={props.value}
      variant={props.isStandard ? 'standard' : 'outlined'}
      onChange={props.onChange}
      sx={{ minWidth: 120 }}
      multiline={props.multiline ?? false}
      rows={props.rows ?? (props.multiline ? 4 : 1)}
    />
  );
};

export default SercomTextField;

