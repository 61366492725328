import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography, useTheme } from '@mui/material';
import React from 'react';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { TransitionProps } from '@mui/material/transitions';
import SercomButton from './SercomButton';
import { t } from 'i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SercomConfirmationDialogProps {
  isOpen: boolean;
  onClose: any;
  message: string;
  buttonText: string;
  isDestructive?: boolean;
  action: any;
}

const SercomConfirmationDialog = (props: SercomConfirmationDialogProps) => {
  const theme = useTheme();
  function PaperComponent(props: PaperProps) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }

  const onClick = () => {
    props.onClose();
    props.action();
  };

  return (
    <Dialog
      // fullScreen
      // `isOpen` is managed only by 'PopupManager'
      open={props.isOpen}
      onClose={props.onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle id="draggable-dialog-title">
        <Typography variant="h4" color={theme.palette.secondary.main}>
          Confirmation
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: 3, mb: 1 }}>
        <Typography variant="h5">{props.message}</Typography>
      </DialogContent>
      <DialogActions>
        <SercomButton text={t('Cancel')} variant="text" onClick={props.onClose} />
        <SercomButton
          isDestructive={props.isDestructive != null ? props.isDestructive : true}
          text={props.buttonText}
          variant="contained"
          onClick={onClick}
        />
      </DialogActions>
    </Dialog>
  );
};

export default SercomConfirmationDialog;

