import React, { useState } from 'react';
import Checkbox from '../Checkbox';
import { t } from 'i18next';
import { createRoot } from 'react-dom/client';

// const root = createRoot(document.getElementById('root') as HTMLElement);

type CheckboxOption = {
  label: string;
  value: any;
};

type InternalCheckboxListProps = {
  options: CheckboxOption[];
  userSelection: CheckboxOption[];
  onChange: (args: any) => void;
};

const InternalCheckboxList = (props: InternalCheckboxListProps) => {
  const { options, userSelection, onChange } = props;
  const [userSelectionState, setUserSelectionState] = useState(userSelection);

  const toggleSelectAll = (args: any) => {
    const result = args.checked ? [...options] : [];

    setUserSelectionState(result);
    onChange?.(result);
  };

  const handleOnChange = (selectedOption: CheckboxOption) => {
    let result;

    if (userSelectionState.some((option) => option.value === selectedOption.value)) {
      result = userSelectionState.filter((option) => option.value !== selectedOption.value);
    } else {
      result = [...userSelectionState, selectedOption];
    }

    setUserSelectionState(result);
    onChange(result);
  };

  return (
    <>
      {options.length && (
        <Checkbox label={t('SelectAll')} onChange={toggleSelectAll} checked={options?.length === userSelectionState.length} />
      )}
      {options.map((option) => (
        <Checkbox
          key={option.value}
          label={option.label}
          checked={userSelectionState.some((selectedOption) => selectedOption.value === option.value)}
          onChange={() => handleOnChange(option)}
        />
      ))}
    </>
  );
};

const getEnumColumnFilter = (options: CheckboxOption[] = []) => ({
  ui: {
    filterData: {
      selectedOptions: options,
      userSelection: options,
    },
    create: (args: any) => {
      const filterData = args.column.filter.ui.filterData;
      const currentFilter = args.column.parent.filterModule.actualPredicate;

      filterData.selectedOptions = currentFilter[args.column.field] ?? options;
      args.dialogObj.element.classList.add('n-enum-column-filter-popup');

      // Initialize the temporary selection array
      filterData.userSelection = filterData.selectedOptions;

      const renderedOptions = (
        <InternalCheckboxList
          onChange={(selectedOptions: CheckboxOption[]) => {
            filterData.selectedOptions = selectedOptions;
          }}
          options={options}
          userSelection={filterData.userSelection}
        />
      );
      const root = createRoot(args.target);
      root.render(renderedOptions);
    },
    read: (args: any) => {
      const filterData = args.column.filter.ui.filterData;

      // Persist the selection from temporary selection array into the selected options list
      // filterData.selectedOptions = filterData.userSelection;
      const selectedValues = filterData.selectedOptions.map((option: any) => option.value);
      const isAllSelected = selectedValues.length === options.length;

      args.fltrObj.refresh = isAllSelected; // Avoids an unnecessary request when updating the filter;
      args.fltrObj.removeFilteredColsByField(args.column.field);

      if (!isAllSelected) {
        args.fltrObj.refresh = true;
        args.fltrObj.filterByColumn(args.column.field, 'equal', selectedValues);
      }
    },
    // Do not remove empty write function, it is required
    write: () => {}, // NOSONAR
  },
});

export { getEnumColumnFilter };

