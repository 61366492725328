import { Link } from '@mui/material';
import React from 'react';
import { SercomTypes } from '../support/sercom-types';

const SercomLink = (props: SercomTypes.SercomLink) => {
  return (
    <Link underline="hover" component="button" onClick={props.onClick} sx={{ pl: 1, pr: 1 }}>
      {props.text}
    </Link>
  );
};

export default SercomLink;

