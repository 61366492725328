import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography, useTheme } from '@mui/material';
import React from 'react';
// import Paper, { PaperProps } from '@mui/material/Paper';
// import Draggable from 'react-draggable';

import { TransitionProps } from '@mui/material/transitions';
import SercomButton from './SercomButton';
import { t } from 'i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SercomDialogProps {
  title: string;
  isOpen: boolean;
  onClose: any;
  buttonText?: string;
  action: any;
  children: any;
}

const SercomDialog = (props: SercomDialogProps) => {
  const theme = useTheme();
  // function PaperComponent(props: PaperProps) {
  //   return (
  //     <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
  //       <Paper {...props} />
  //     </Draggable>
  //   );
  // }
  // const onClick = () => {
  //   props.action();
  // };

  return (
    <Dialog
      // fullScreen
      scroll="paper"
      disableRestoreFocus
      open={props.isOpen}
      onClose={props.onClose}
      // PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      TransitionComponent={Transition}
    >
      <DialogTitle id="draggable-dialog-title" sx={{ mt: 1, mb: 1 }}>
        <Typography variant="h3" color={theme.palette.secondary.main}>
          {props.title}
        </Typography>
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions sx={{ mr: 2, mb: 2 }}>
        <SercomButton text={t('Cancel')} variant={props.buttonText ? 'text' : 'contained'} onClick={props.onClose} />
        {props.buttonText && <SercomButton text={props.buttonText} variant="contained" onClick={props.action} />}
      </DialogActions>
    </Dialog>
  );
};

export default SercomDialog;

