import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SercomTypes } from '../../support/sercom-types';
import SercomDialog from '../../components/SercomDialog';
import SercomEnums from '../../support/sercom-enums';
import { useTranslation } from 'react-i18next';
import snackbarHelper from '../../helpers/snackbar-helper';
import dayjs from 'dayjs';
import SercomSelect from '../../components/SercomSelect';
import SercomDatePicker from '../../components/SercomDatePicker';
import SercomTextField from '../../components/SercomTextField';
import LeadService from '../../services/lead-service';

const LeadPopup = (props: SercomTypes.SercomDialog) => {
  const leadCategories: SercomTypes.SercomItem[] = [
    { key: SercomEnums.LeadCategory.Plumbing, value: 'Plumbing' },
    { key: SercomEnums.LeadCategory.Electricity, value: 'Electricity' },
    { key: SercomEnums.LeadCategory.Paint, value: 'Paint' },
    { key: SercomEnums.LeadCategory.HeatingAirConditioning, value: 'HeatingAirConditioning' },
    { key: SercomEnums.LeadCategory.ChimneySweeping, value: 'ChimneySweeping' },
    { key: SercomEnums.LeadCategory.WaterTreatment, value: 'WaterTreatment' },
    { key: SercomEnums.LeadCategory.Gutter, value: 'Gutter' },
    { key: SercomEnums.LeadCategory.DoorWindow, value: 'DoorWindow' },
    { key: SercomEnums.LeadCategory.Flooring, value: 'Flooring' },
    { key: SercomEnums.LeadCategory.GarageDoor, value: 'GarageDoor' },
    { key: SercomEnums.LeadCategory.Exterminator, value: 'Exterminator' },
  ];
  const leadStatuses: SercomTypes.SercomItem[] = [
    { key: SercomEnums.LeadStatus.Enabled, value: 'Enabled' },
    { key: SercomEnums.LeadStatus.Disabled, value: 'Disabled' },
    { key: SercomEnums.LeadStatus.Converted, value: 'Converted' },
  ];

  const [lead, setLead] = useState<SercomTypes.Lead>({
    category: SercomEnums.LeadCategory.Plumbing,
    status: SercomEnums.LeadStatus.Enabled,
    language: SercomEnums.Language.French,
  });
  const [emailError, setEmailError] = useState<boolean>(false);
  const [nameError, setNameError] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function init() {
      if (props.id) {
        const result = await LeadService.getLead(props.id);
        if (result.isSuccess) {
          setLead(result.data);
        }
      }
    }

    init();
  }, []);

  const onNameChange = (event: any) => {
    setLead({ ...lead, name: event.target.value });
  };
  const onEmailChange = (event: any) => {
    setLead({ ...lead, email: event.target.value });
  };
  const onPhoneChange = (event: any) => {
    setLead({ ...lead, phone: event.target.value });
  };
  const onAddressChange = (event: any) => {
    setLead({ ...lead, address: event.target.value });
  };
  const onSourceChange = (event: any) => {
    setLead({ ...lead, source: event.target.value });
  };
  const onUrlChange = (event: any) => {
    setLead({ ...lead, url: event.target.value });
  };
  const onLeadCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLead({ ...lead, category: parseInt(event.target.value) });
  };
  const onLeadStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLead({ ...lead, status: parseInt(event.target.value) });
  };
  const onFirstContactDateChange = (value: dayjs.Dayjs) => {
    setLead({ ...lead, firstContactDate: value ? value.toDate() : null });
  };
  const onFirstContactDateClear = () => {
    setLead({ ...lead, firstContactDate: null });
  };
  const onFollowup1DateChange = (value: dayjs.Dayjs) => {
    setLead({ ...lead, followup1Date: value ? value.toDate() : null });
  };
  const onFollowup1DateClear = () => {
    setLead({ ...lead, followup1Date: null });
  };

  const validate = (lead: SercomTypes.Lead): SercomEnums.ErrorCode => {
    setNameError(!lead.name);
    setEmailError(!lead.email);

    if (!lead.name) {
      return SercomEnums.ErrorCode.InvalidName;
    }
    if (!lead.email) {
      return SercomEnums.ErrorCode.InvalidEmail;
    }

    return SercomEnums.ErrorCode.None;
  };

  const onSave = async () => {
    const errorCode: number = validate(lead);
    if (errorCode != SercomEnums.ErrorCode.None) {
      snackbarHelper.showErrorFromCode(errorCode, t);
      return;
    }
    const result = await LeadService.saveLead(lead);
    if (result.isSuccess && result.data) {
      if (result.data.errorCode && result.data.errorCode != SercomEnums.ErrorCode.None) {
        snackbarHelper.showErrorFromCode(result.data.errorCode, t);
      } else {
        snackbarHelper.showSuccess(t('LeadUpdatedSuccessfully'));
        props.action();
        props.onClose();
      }
    } else {
      snackbarHelper.showErrorFromCode(SercomEnums.ErrorCode.ServerError, t);
    }
  };

  return (
    <SercomDialog
      title={t(lead?.id ? 'UpdateLead' : 'CreateLead')}
      isOpen={props.isOpen}
      onClose={props.onClose}
      buttonText={props.buttonText}
      action={onSave}
    >
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <SercomSelect title={t('Category')} items={leadCategories} selectedItem={lead?.category} onChange={onLeadCategoryChange} />
        </Grid>
        <Grid item xs={6}>
          <SercomSelect title={t('Status')} items={leadStatuses} selectedItem={lead?.status} onChange={onLeadStatusChange} />
        </Grid>
        <Grid item xs={6}>
          <SercomTextField title={t('Name')} isRequired error={nameError} value={lead?.name ?? ''} onChange={onNameChange} />
        </Grid>
        <Grid item xs={6}>
          <SercomTextField title={t('Email')} error={emailError} value={lead?.email ?? ''} onChange={onEmailChange} />
        </Grid>
        <Grid item xs={6}>
          <SercomTextField title={t('Phone')} value={lead?.phone ?? ''} onChange={onPhoneChange} />
        </Grid>
        <Grid item xs={6}>
          <SercomTextField title={t('Address')} value={lead?.address ?? ''} onChange={onAddressChange} />
        </Grid>
        <Grid item xs={6}>
          <SercomTextField title={t('Url')} value={lead?.url ?? ''} onChange={onUrlChange} />
        </Grid>
        <Grid item xs={6}>
          <SercomTextField title={t('Source')} value={lead?.source ?? ''} onChange={onSourceChange} />
        </Grid>
        <Grid item xs={6}>
          <SercomDatePicker
            title={t('FirstContactDate')}
            value={lead.firstContactDate != null ? dayjs(lead.firstContactDate) : null}
            onChange={onFirstContactDateChange}
            onClear={onFirstContactDateClear}
          />
        </Grid>
        <Grid item xs={6}>
          <SercomDatePicker
            title={t('Followup1Date')}
            value={lead.followup1Date != null ? dayjs(lead.followup1Date) : null}
            onChange={onFollowup1DateChange}
            onClear={onFollowup1DateClear}
          />
        </Grid>
      </Grid>
    </SercomDialog>
  );
};

export default LeadPopup;

