import React from 'react';
import { ListItemText, ListItemIcon, ListItem, ListItemButton, Typography } from '@mui/material';

type SercomMenuItemProps = {
  title: string;
  icon: any;
  selected?: boolean;
  callbackAction: any;
};

const SercomMenuItem = (props: SercomMenuItemProps) => {
  const { title, icon, callbackAction } = props;

  return (
    <ListItem key={title} disablePadding sx={{ display: 'block', minWidth: 200 }} onClick={callbackAction}>
      <ListItemButton
        sx={{
          minHeight: 40,
          px: 1.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 1,
            justifyContent: 'center',
          }}
        >
          {icon}
          {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="h5" sx={{ fontWeight: props.selected ? 'bold' : 'normal' }}>
              {title}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default SercomMenuItem;

