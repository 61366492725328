import React from 'react';
import { LoadingRectangle, LoadingCircle } from './Loading.styled';
import SercomEnums from '../../support/sercom-enums';

type LoadingProps = {
  format?: SercomEnums.LoadingFormat;
  width: number;
  height: number;
};

const Loading = (props: LoadingProps) => {
  const { format = SercomEnums.LoadingFormat.Rectangle, width, height } = props;

  return (
    <>
      {format === SercomEnums.LoadingFormat.Rectangle && <LoadingRectangle width={width} height={height} />}
      {format === SercomEnums.LoadingFormat.Circle && <LoadingCircle width={width} height={height} />}
    </>
  );
};

export default Loading;
