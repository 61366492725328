import React, { useState } from 'react';
import { SercomTypes } from '../../support/sercom-types';
import SercomEnums from '../../support/sercom-enums';
import snackbarHelper from '../../helpers/snackbar-helper';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import SercomButton from '../../components/SercomButton';
import SercomUploadFile from '../../components/SercomUploadFile';
import ImportService from '../../services/import-service';

const ImportFile = (props: SercomTypes.ImportFile) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { t } = useTranslation();

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const onClear = () => {
    setSelectedFile(null);
  };

  const onImport = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      // if (formData.entries.length > props.maxRows) {
      //   snackbarHelper.showErrorFromCode(SercomEnums.ErrorCode.MaxRowsExceeded, t);
      //   return;
      // }
      let result = null as any;
      let message = '';
      switch (props.type) {
        case SercomEnums.ImportType.Lead:
          result = await ImportService.importLead(formData);
          message = 'ClientsExportedSuccessfully';
          break;
      }
      if (result.isSuccess && result.data) {
        if (result.data.errorCode && result.data.errorCode != SercomEnums.ErrorCode.None) {
          snackbarHelper.showErrorFromCode(result.data.errorCode, t);
        } else {
          onClear();
          props.onImported();
          snackbarHelper.showSuccess(t(message, { count: result.data.data }));
        }
      } else {
        snackbarHelper.showErrorFromCode(SercomEnums.ErrorCode.ServerError, t);
      }
    }
  };

  return (
    <form encType="multipart/form-data">
      <Stack direction={props.columnView ? 'column' : 'row'} spacing={3}>
        {/* <label htmlFor={guid} className="custom-file-upload">
        {t('SelectFile')}
      </label>
      <input ref={importRef} type="file" id={guid} accept=".csv" onChange={onFileChange} /> */}
        <SercomUploadFile
          text={t('SelectFile')}
          variant="outlined"
          accept=".csv"
          onClick={onFileChange}
          onClear={onClear}
        ></SercomUploadFile>
        {selectedFile && <SercomButton text="Import" variant="contained" onClick={onImport} />}
      </Stack>
    </form>
  );
};

export default ImportFile;

