import { Button } from '@mui/material';
import React from 'react';
import { SercomTypes } from '../support/sercom-types';

const SercomButton = (props: SercomTypes.SercomButton) => {
  return (
    <Button
      color={props.isDestructive ? 'error' : 'primary'}
      variant={props.variant}
      onClick={props.onClick}
      sx={{ pl: 5, pr: 5, textTransform: 'none' }}
    >
      {props.text}
    </Button>
  );
};

export default SercomButton;

