import APIRequestHelper from '../helpers/api-request-helper';

const ImportService = {
  async importLead(formData: FormData) {
    try {
      return APIRequestHelper.sendRequest('POST', 'admin/import_lead', false, formData, true);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  },
};
export default ImportService;

