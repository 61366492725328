import { enqueueSnackbar } from 'notistack';
import SercomEnums from '../support/sercom-enums';
import { TFunction } from 'i18next';

const snackbarHelper = {
  show: (message: string) => {
    enqueueSnackbar(message, { variant: 'default', preventDuplicate: true, autoHideDuration: 3000 });
  },
  showError: (message: string) => {
    enqueueSnackbar(message, { variant: 'error', preventDuplicate: true, autoHideDuration: 3000 });
  },
  showErrorFromCode: (errorCode: SercomEnums.ErrorCode, t: TFunction<any, undefined, any>) => {
    snackbarHelper.showError(t(`ErrorCode.${SercomEnums.ErrorCode[errorCode]}`));
  },
  showSuccess: (message: string) => {
    enqueueSnackbar(message, { variant: 'success', autoHideDuration: 3000 });
  },
  showWarning: (message: string) => {
    enqueueSnackbar(message, { variant: 'warning', autoHideDuration: 3000 });
  },
  showInfo: (message: string) => {
    enqueueSnackbar(message, { variant: 'info', autoHideDuration: 3000 });
  },
};

export default snackbarHelper;
