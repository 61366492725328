import APIRequestHelper from '../helpers/api-request-helper';
import { StorageService } from '../services';

const UserService = {
  async login(username: string, password: string) {
    const payload = {
      username: username,
      password: password,
    };
    const response = await APIRequestHelper.sendRequest('POST', 'authenticate/login_system', true, payload);

    if (response.isSuccess && response.data?.['token']) {
      // localStorage.setItem('8gvfk3g3jm', username);
      StorageService.setCurrentName(response.data['name']);
      StorageService.setToken(response.data['token']);
      StorageService.setCurrentUserId(response.data['userId']);
      StorageService.setCurrentCompanyId(response.data['companyId']);
      StorageService.setPermission(response.data['permission']);
      StorageService.setLanguage(response.data['language']);

      return response.data;
    }

    UserService.logout();

    return null;
  },

  logout(): boolean {
    StorageService.clearAll();
    return true;
  },
  isLogged(): boolean {
    const token = StorageService.getToken();
    return token !== null && token !== '';
  },
  async getUser(id: number) {
    return APIRequestHelper.sendRequest('GET', `admin/users/${id}`, false);
  },
};

export default UserService;

