import { ThunkPropsInterface } from '../../helpers/store-helper';
import { UserService, StorageService } from '../../services';
import SercomEnums from '../../support/sercom-enums';
import { SercomTypes } from '../../support/sercom-types';
import { metadataActions } from './metadata-actions';

export const metadataThunks = {
  login: async (
    { dispatch, actionSuccess, actionError }: ThunkPropsInterface,
    email: string,
    password: string,
    successCallback?: Function,
    errorCallback?: Function
  ) => {
    const data = await UserService.login(email, password);

    if (data) {
      dispatch(actionSuccess(data));
      dispatch(metadataActions.getLoggedInUserData);
      successCallback?.();
    } else {
      dispatch(actionError());
      errorCallback?.();
    }
  },

  register: async (
    { dispatch, actionSuccess, actionError }: ThunkPropsInterface,
    email: string,
    password: string,
    successCallback?: Function,
    errorCallback?: Function
  ) => {
    const data = await UserService.register(email, password);

    if (data) {
      dispatch(actionSuccess(data));
      dispatch(metadataActions.getLoggedInUserData);
      successCallback?.();
    } else {
      dispatch(actionError());
      errorCallback?.();
    }
  },

  googleLogin: async (
    { dispatch, actionSuccess, actionError }: ThunkPropsInterface,
    tokenId: string,
    successCallback?: Function,
    errorCallback?: Function
  ) => {
    const data = await UserService.googleLogin(tokenId);

    if (data) {
      dispatch(actionSuccess(data));
      dispatch(metadataActions.getLoggedInUserData);
      successCallback?.();
    } else {
      dispatch(actionError());
      errorCallback?.();
    }
  },

  logout: async ({ dispatch, actionSuccess, actionError }: ThunkPropsInterface, successCallback?: Function) => {
    try {
      const isSuccess = UserService.logout();

      if (isSuccess) {
        dispatch(actionSuccess({ isLogged: false, companyId: null }));
        successCallback?.();
      } else {
        dispatch(actionError());
      }
    } catch (error) {
      dispatch(actionError());
    }
  },

  getLoggedInUserData: async ({ dispatch, actionSuccess, actionError }: ThunkPropsInterface, callback?: Function) => {
    try {
      const isLogged = UserService.isLogged();
      const currentUserId = StorageService.getCurrentUserId();
      const currentCompanyId = StorageService.getCurrentCompanyId();
      const permission = StorageService.getPermission();
      const language = StorageService.getLanguage();

      if (!isLogged) {
        // dispatch(actionSuccess({}));
        dispatch(actionError());
        callback?.();
        return;
      }

      if (!currentCompanyId) {
        dispatch(actionError());
        callback?.();
        return;
      }

      // const response = await UserService.getUser(loggedInUserId);
      // const user = response.data?.user_profiles?.[0];

      // if (!response.isSuccess || !user) {
      //   dispatch(actionError());
      //   callback?.();
      //   return;
      // }

      dispatch(actionSuccess({ isLogged, companyId: currentCompanyId, currentUserId, permission: permission, language: language }));
      callback?.();
    } catch (error) {
      dispatch(actionError());
    }
  },

  // createCompany: async (
  //   { dispatch, actionSuccess, actionError }: ThunkPropsInterface,
  //   name: string,
  //   successCallback?: Function,
  //   errorCallback?: Function
  // ) => {
  //   try {
  //     const result = await CompanyService.create(name);

  //     if (result) {
  //       dispatch(actionSuccess({ company: result.data }));
  //       successCallback?.();
  //     } else {
  //       dispatch(actionError());
  //       errorCallback?.();
  //     }
  //   } catch (error) {
  //     dispatch(actionError());
  //   }
  // },

  getDisplayMode: async ({ dispatch, actionError }: ThunkPropsInterface, callback?: Function) => {
    try {
      const displayMode = StorageService.getDisplayMode();

      if (displayMode == null) {
        dispatch(metadataActions.setDisplayMode(SercomEnums.DisplayMode.Light));
        callback?.();
        return;
      }

      // const response = await UserService.getUser(loggedInUserId);
      // const user = response.data?.user_profiles?.[0];

      // if (!response.isSuccess || !user) {
      //   dispatch(actionError());
      //   callback?.();
      //   return;
      // }

      dispatch(metadataActions.setDisplayMode(displayMode));
      callback?.();
    } catch (error) {
      dispatch(actionError());
    }
  },

  setDisplayMode: async (
    { dispatch, actionSuccess, actionError }: ThunkPropsInterface,
    displayMode: SercomEnums.DisplayMode,
    callback?: Function
  ) => {
    try {
      StorageService.setDisplayMode(displayMode);

      dispatch(actionSuccess({ displayMode: displayMode }));
      callback?.();
    } catch (error) {
      dispatch(actionError());
    }
  },
  // getCurrentEvent: async ({ dispatch, getState, actionSuccess, actionError }: ThunkPropsInterface, callback?: Function) => {
  //   try {
  //     const event = getState().metadata.currentEvent;
  //     dispatch(actionSuccess({ currentEvent: event }));
  //     callback?.();
  //   } catch (error) {
  //     dispatch(actionError());
  //   }
  // },
  setCurrentEvent: async ({ dispatch, actionSuccess, actionError }: ThunkPropsInterface, event: SercomTypes.Event, callback?: Function) => {
    try {
      dispatch(actionSuccess({ currentEvent: event }));
      callback?.();
    } catch (error) {
      dispatch(actionError());
    }
  },
  removeCurrentEvent: async ({ dispatch, actionSuccess, actionError }: ThunkPropsInterface, callback?: Function) => {
    try {
      dispatch(actionSuccess());
      callback?.();
    } catch (error) {
      dispatch(actionError());
    }
  },
  setLanguage: async (
    { dispatch, actionSuccess, actionError }: ThunkPropsInterface,
    language: SercomEnums.Language,
    callback?: Function
  ) => {
    try {
      dispatch(actionSuccess({ language: language }));
      callback?.();
    } catch (error) {
      dispatch(actionError());
    }
  },
};

