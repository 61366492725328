import React from 'react';
import { SercomTypes } from '../support/sercom-types';
import { FormControlLabel, Switch } from '@mui/material';

const SercomSwitch = (props: SercomTypes.SercomCheckbox) => {
  return (
    <FormControlLabel
      sx={{ ml: 0 }}
      label={props.label}
      labelPlacement="start"
      control={<Switch disabled={props.disabled ?? false} checked={props.checked} onChange={props.onChange} />}
    ></FormControlLabel>
  );
};

export default SercomSwitch;

